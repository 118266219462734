import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, NavLink } from 'react-router-dom'
import './style.css'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      block1: false,
      block2: false,
      block3: false,
      work: [],
      faq: [],
    }
  }
  componentWillMount() {
    let { Usergroup } = JSON.parse(localStorage.getItem('user'))

    let obj = {
      Title: Usergroup,
    } /*
      for(let x of document.querySelectorAll('.card-header')){
        x.classList.add('cardAnimation');
      }
    */

    // console.log(ar.sort( compare ))
    axios
      .post('providers/GetAllWorking&Faqs', obj)
      .then((res) => {
        // console.log(res.data)
        if (res.data.status === 200) {
          let workin = res.data.data.working
          let fq = res.data.data.FAQ
          console.log(fq,workin)
          this.setState({ work: workin, faq: fq })
        }
      })
      .catch((err) => {
        console.log('No data error occurred' + err)
      })
  }
  componentDidUpdate() {
    for (let x of document.querySelectorAll('.card-header')) {
      x.classList.add('cardAnimation')
    }
  }
  card = (e) => {
    if (!e.target.classList.contains('is-expanded')) {
      e.target.classList.add('is-expanded');
    let node2=  e.target.nextElementSibling;
    node2.classList.add('showme');
    console.log(node2)
    } else {
      
      let node2=  e.target.nextElementSibling;
      console.log(node2)
    node2.classList.remove('showme');
      e.target.classList.remove('is-expanded')
      
    }
    for (let x of document.querySelectorAll('.is-expanded')) {
     
      let node2= document.querySelector('.is-expanded').nextElementSibling;
    node2.classList.remove('showme');
      if (x != e.target) x.classList.remove('is-expanded')
    }
  }

  render() {
    let i = 0;

    let work = this.state.work
    let faq = this.state.faq

    return (
      <div className="containers">
        <div className="container2">
          <Accordion>
            {work.map((el) => {
              i=i+1;
              console.log(i)
              return (
                <Card key={el.toString()}>
                  <Accordion.Toggle as={Card.Header} onClick={this.card} eventKey={i}>
                    {el.Title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={i}>
                    <Card.Body>{el.desc}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            })}

            <Card>
              <Accordion.Toggle onClick={this.card} as={Card.Header} eventKey={++i}>
                FAQ's
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={i}>
                <Card.Body>
                  <Accordion>
                    {faq.map((el) => {
                      i++
                      return (
                        <Card>
                          <Accordion.Toggle as={Card.Header} onClick={this.card} eventKey={i}>
                            {el.question}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={i}>
                            <Card.Body>{el.answer}</Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )
                    })}
                  </Accordion>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    )
  }
}
export default Welcome

import React from 'react'
import Success from '../../../Alert/Success'
import { Link, Redirect } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import ImageUploader from 'react-images-upload'
import DatePicker from 'react-date-picker'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import './Update.css'
import auth from '../../../Auth/auth'
import Select from 'react-select'
import axios from 'axios'

const urls = ''
const Months = [
  {value:"January",label:"January"}, {value:"February",label:'February'}, {value:"March",label:"March"}, {value:"April",label:"April"}, {value:"May",label:"May"}, {value:"June",label:"June"},{ value:"July",label:"July"},{value: "August",label:"August"}, {value:"September",label:"September"}, {value:"October",label:"October"}, {value:"November",label:"November"}, {value:"December",label:"December"}
]
class Update extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      username: '',
      id: -1,
      categories: [],
      subcategories: [],
      Name: '',
      Prize:false,
      Monthly:false,
      Month:"January",
      ImgTemp: '',
      Img: '',
      PermImg: '',
      ImgFrame: '',
      Category: '',
      Activation: false,
      SubCat: '',
      redirect: false,
      promotion: false,
      status: false,
      Success: false,
      Success2: false,
      msg: '',
      loadingBarProgress: 0,
      cat_title: '',
      region: '',

    }
  }

  componentDidMount() {
    console.log(auth.UserGroupTitle)
    let user = JSON.parse(localStorage.getItem('user'))
    if(!user.user_name)
    user=JSON.parse(localStorage.getItem('username'))
    console.log(JSON.parse(localStorage.getItem('user')))
    let path = window.location.pathname
    let usergroup = JSON.parse(localStorage.getItem('user-group')).title
    console.log(usergroup)
    if (path === '/Home/EditProductLS' && localStorage.getItem('EditProductId')) {
      let m = JSON.parse(localStorage.getItem('EditProductId'))

      let { user_name } = user
      let object = {
        username: user_name,
        id: m.id,
      }
      axios
        .post('https://provider.beelicensed.com/providers/getSubCatByCat', object)
        .then((res) => {
          console.log(res.data.status)
          if (res.data.status === 200) {
            let info = res.data.Product
            let {
              _id,
              Name,
              Category,
              SubCat,
             
              region,
              Activation,
             
              Img,
              Prize,
              promotion,
             
            } = info
              this.setState({
                username: user_name,
                region: region,
                id: _id,
                promotion: promotion,
                Name: Name,
                Category: Category,
                SubCat: SubCat,
                subcategories: res.data.data,
                cat_title: res.data.title,
                Monthly:info.Monthly,
      Month:info.Month,
                Activation: Activation,
               Prize:Prize,
                PermImg: Img.Img,
               
              })
           
            
          } else this.setState({ status: true, Success: false, msg: res.data.msg })
        })
        .catch((err) => {
          console.log(err)
          this.setState({ status: true, Success: false, msg: 'Network error' })
        })

      // axios.post("/providers/CatInfo",this.state)
    } else {
      //const regio=JSON.parse(localStorage.getItem('region'))._id
      const categories = JSON.parse(localStorage.getItem('selected-cat'))
      let { user_name } = user

        let { _id } = JSON.parse(localStorage.getItem('region'))
        console.log(JSON.parse(localStorage.getItem('region')))
        this.setState({ username: user_name, region: _id, categories: categories })
      
    }
  }

  SubmitForm = (event) => {
    event.preventDefault()
    let state = this.state
    //AddProductOnly
    this.add(99)
    console.log(this.state)
    if (this.Validate()) {
      let path = window.location.pathname
      let paths = ''
      if (path === '/Home/EditProductLS')
      paths = '/providers/EditLoyalSwanProduct';
      else
      paths = '/providers/AddLoyalSwanProduct';
       
          let Form = new FormData()
          Form.append('file', state.Img)
          Form.append('data', JSON.stringify(state))
          console.log(this.state)
        axios
          .post(paths, Form)
          .then((result) => {
            this.complete()
            if (result.data.status === 200) {
              this.setState({ status: true, Success: true, msg: 'form Submitted Successfully' })
            } else this.setState({ status: true, Success: false, msg: result.data.msg })
          })
          .catch((err) => {
            this.complete()
            this.setState({ status: true, Success: false, msg: 'Went wrong' })
          })
      
    } else this.setState({ status: true, Success: false, msg: 'fields are empty cannot proceed' })
  }

  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, Success2: true })
    else this.setState({ status: false, Success2: false })
  }

  Fun = (e, value) => {
    this.setState({ selected: value })
  }
 
  Validate = () => {
    console.log(this.state)
    if (
     
      this.state.Name &&
      this.state.Category &&
      this.state.SubCat 
     
    )
      return true
    ///  if(this.state.)
    else return false
  }

  add = (value) => {
    this.setState({
      loadingBarProgress: this.state.loadingBarProgress + value,
    })
  }
  complete = () => {
    this.setState({ loadingBarProgress: 100 })
  }
  onLoaderFinished = () => {
    this.setState({ loadingBarProgress: 0 })
  }
  changeCat = (e) => {
    var Cat2 = this.state.Category
    Cat2 = e.target.value
    let caba = JSON.parse(localStorage.getItem('selected-cat'))
    let index = 0
    for (let km = 0; km < caba.length; km++) {
      if (caba[km].value === e.target.value) {
        index = km
      }
    }
    let obj = {
      sub_categories: caba[index].sub,
    }
    axios
      .post('https://provider.beelicensed.com/providers/getSubCatByRef', obj)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ Category: Cat2, subcategories: res.data.data })
        } else {
          console.log('some issue in api')
          this.setState({ status: true, Success: false, msg: 'something went wrong' })
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({ status: true, Success: false, msg: 'network error' })
      })
  }

  render() {
    // let i,
    //   u = -1
    // let user = JSON.parse(localStorage.getItem('user'))
    // let usergroup = JSON.parse(localStorage.getItem('user-group')).title
    // console.log(JSON.parse(localStorage.getItem('user-group')).title)
    let path = window.location.pathname

    let Subs = this.state.subcategories
    let categories = this.state.categories

    // console.log(MinDate)
    return (
      <div>
        <LoadingBar
          progress={this.state.loadingBarProgress}
          height={4}
          color="black"
          onLoaderFinished={() => this.onLoaderFinished()}
        />
        {this.state.redirect ? <Redirect to="/Home/Calendar" /> : null}
        {this.state.Success2 ? <Redirect to="/Home/Products" /> : null}

        {this.state.StockDirect ? <Redirect to="/Home/Stock" /> : null}
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <Form className="Form" onSubmit={this.SubmitForm}>
                <div className="card-header ">
                  <h4 className="card-title">Add Product</h4>
                </div>

                <div className="card-body">
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridCurrentPass">
                      <Form.Label>Naam (maximaal 50 tekens) *</Form.Label>
                      <Form.Control
                        required
                        maxlength="50"
                        type="text"
                        placeholder="Naam "
                        value={this.state.Name}
                        onChange={(e) => {
                          console.log(e.target.value)
                          this.setState({ Name: e.target.value })
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group controlId="formGridState">
                      <Form.Label>Categorieën * </Form.Label>

                      {window.location.pathname != '/Home/EditProductLS' ? (
                        <Form.Control as="select" onChange={this.changeCat}>
                          <option value="" selected disabled hidden>
                            Kies een categorie
                          </option>

                          {categories.map((el) => {
                            let number = el

                            return (
                              <option key={number} value={number.value}>
                                {number.name}
                              </option>
                            )
                          })}
                        </Form.Control>
                      ) : (
                        <Form.Control as="select" disabled={true}>
                          <option value={this.state.Category}>{this.state.cat_title}</option>
                        </Form.Control>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>

                    {this.state.Category != '' ? (
                      <Form.Group controlId="formGridCurrentPass">
                        <Form.Label>Soort deal *</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          onChange={(e) => {
                            let Cat2 = this.state.SubCat
                            Cat2 = e.target.value
                            this.setState({ SubCat: Cat2 })
                          }}
                        >
                          <option value="none" selected disabled hidden>
                            Kies een deal
                          </option>

                          {Subs.length >= 0
                            ? Subs.map((el) => {
                                let name = el
                                if (name._id === this.state.SubCat) {
                                  return (
                                    <option value={name._id} selected>
                                      {name.name}
                                    </option>
                                  )
                                } else {
                                  return <option value={name._id}>{name.name}</option>
                                }
                              })
                            : null}
                        </Form.Control>
                      </Form.Group>
                    ) : null}
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="switch"
                        id="custom-switch1"
                        label="Product "
                        checked={this.state.Activation}
                        value={this.state.Activation}
                        onChange={(e) => {
                          this.setState({ Activation: e.target.checked })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="switch"
                        label="Advertisement"
                        id="custom-switch2"
                        checked={this.state.promotion}
                        value={this.state.promotion}
                        onChange={(e) => {
                          this.setState({ promotion: e.target.checked })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        label="Prize/Ad/Product "
                        type="switch"
                        id="custom-switch3"
                       checked={this.state.Prize}
                        onChange={(e) => {
                          console.log(e.target)
                          if(e.target.checked)
                          this.setState({ Prize: e.target.checked })
                          else 
                          this.setState({ Monthly:false,Month:'',Prize: e.target.checked })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                 {this.state.Prize? 
                  <Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Month"
                        checked={this.state.Monthly}
                        value={this.state.Monthly}
                        onChange={(e) => {
                          this.setState({ Monthly: e.target.checked })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>: null
                  }

                  {
                    this.state.Monthly? 
                  <Form.Row>
                  <Form.Group as={Col}  controlId="formGridState5">
                    <Form.Label>Months </Form.Label>
{console.log(this.state.Month)}
                    <Select
                        value={Months.find(o=> o.value===this.state.Month)}
                        onChange={(e) => {
                          console.log(e)
                          this.setState({ Month: e.value })
                        }}
                        options={Months}
                      />
                  
                          </Form.Group>
                     <Form.Group as={Col} controlId="formGridCurrentPass">  </Form.Group>
                     
                  </Form.Row> 
                  :null
                  }


                  <Form.Row>
                   
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>Afbeelding *</Form.Label>

                        <div className="fileUploader ">
                          <div className="fileContainer">
                            {this.state.ImgTemp ? (
                              <div className="ImageFrame">
                                <div
                                  className="deleteImage"
                                  onClick={() => {
                                    let file = document.querySelector('.File')

                                    file.value = ''
                                    this.setState({ ImgTemp: '', Img: '' })
                                  }}
                                >
                                  X
                                </div>
                                <img src={this.state.ImgTemp} className="StockImage" />
                              </div>
                            ) : (
                              <div className="ImageFrame">
                                {path === '/Home/EditProductLS' ? (
                                  <img src={`${this.state.PermImg}`} className="StockImage" />
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            <span className="btn btn-outline-default btn-round btn-file">
                              <span className="fileinput-new">Choose Image</span>
                              <input
                                type="file"
                                single="true"
                                accept="image/*"
                                onChange={(e) => {
                                  // console.log(e.target.files[0]);
                                  if (e.target.files[0])
                                    this.setState({
                                      ImgTemp: URL.createObjectURL(e.target.files[0]),
                                      Img: e.target.files[0],
                                    })
                                }}
                                className="File"
                                name="prImage"
                              />
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    
                  </Form.Row>
              
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Button variant="primary" onClick={this.SubmitForm}>
                        BIJWERKEN
                      </Button>
                      <Link to="/Home/Products">
                        <Button variant="danger">ANNULEER</Button>
                      </Link>
                    </Form.Group>
                    <Form.Group as={Col}></Form.Group>
                    <Form.Group as={Col}></Form.Group>
                    
                  </Form.Row>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Update

import React from 'react'

import { MDBDataTable } from 'mdbreact'
import { Link, Redirect } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import { FiTrash, FiEdit, FiPlusCircle } from 'react-icons/fi'
import './Success.css'
import '../edit.css'
import auth from '../../../Auth/auth'
import axios from 'axios'

const urls = ''
const column = [
  {
    label: 'a',
    field: 'a',
    sort: 'asc',
    width: 50,
  },
  {
    label: 'Producten',
    field: 'product',
    sort: 'asc',
    width: 150,
  },

  {
    label: 'Startdatum',
    field: 'Sdate',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Einddatum',
    field: 'Edate',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Regio',
    field: 'region',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Categorie',
    field: 'category',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Ad',
    field: 'ad',
    sort: 'asc',
    width: 100,
  },
  {
    label: '',
    field: 'status',
    width: 50,
  },
]
const column2 = [
  {
    label: 'a',
    field: 'a',
    sort: 'asc',
    width: 50,
  },
  {
    label: 'Producten',
    field: 'product',
    sort: 'asc',
    width: 150,
  },

  {
    label: 'Startdatum',
    field: 'Sdate',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Einddatum',
    field: 'Edate',
    sort: 'asc',
    width: 100,
  },

  {
    label: 'Categorie',
    field: 'category',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Ad',
    field: 'ad',
    sort: 'asc',
    width: 100,
  },
  {
    label: '',
    field: 'status',
    width: 50,
  },
]
const column3 = [
  {
    label: 'a',
    field: 'a',
    sort: 'asc',
    width: 50,
  },
  {
    label: 'Producten',
    field: 'product',
    sort: 'asc',
    width: 150,
  },

  
  {
    label: 'Month',
    field: 'Month',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Regio',
    field: 'region',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Categorie',
    field: 'category',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Ad',
    field: 'ad',
    sort: 'asc',
    width: 100,
  },
  {
    label: '',
    field: 'status',
    width: 50,
  },
]

class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',

      showAlert: false,
      Subscription: '',
      Edit: false,
      Add: false,
      msg: '',
      removed: true,
      columns: column,
      clicked: -1,
      rows: [
        {
          id: -1,
          a: '',
          product: '',
          Sdate: '',
          Edate: '',
          region: '',
          category: '',
          ad: '',
          src: '',
          status: '',
        },
      ],
    }
  }
  Edit = (e) => {
    if (e) {
      localStorage.setItem('EditProductId', JSON.stringify(this.state.rows[e]))
      let state = {
        user_name:this.state.username
      }
      localStorage.setItem('username', JSON.stringify(state))
      this.setState({ Edit: true, clicked: e })
    }
  }
  del(e) {
    if (e) this.setState({ showAlert: true, clicked: e, msg: '' })
  }
  componentDidMount() {
    this.UpdateRows(0)
  }
  GetCat = async (e) => {
    console.log(e)
    let obj = {
      Id: e,
    }
    const response = await axios.post('https://provider.beelicensed.com/providers/getCatById', obj)

    // console.log(response);
    console.log(response.data)
    return response.data.name
  }
  UpdateRows = (e) => {
    let { user_name, subscription } = JSON.parse(localStorage.getItem('user'))
    // let {Name}=JSON.parse(localStorage.getItem("region"));

    //console.log(JSON.parse(localStorage.getItem("region")))
    //let regio=Name
    console.log(JSON.parse(localStorage.getItem('user')))
    let i = 0
    let dt = new Date(subscription.EndDate)
    let dd = dt.getFullYear() + '-' + dt.getMonth() + '-' + dt.getDate()
    let Object = {
      user: user_name,
    }
    axios
      .post('https://provider.beelicensed.com/providers/GetProducts', Object)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("dbasj")
          let Products = res.data.product
          let rows = []
          console.log(Products)
          Products.map((element) => {
            let { _id, Name, Category, Img, Month,StartDate, EndDate, Activation, region } = element
            let cat = Category.name
            let n = new Date(StartDate)
            console.log(n.getMonth())

            let d = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()
            let n2 = new Date(EndDate)
            console.log(n2.getMonth())
            let d2 = n2.getFullYear() + '-' + (n2.getMonth() + 1) + '-' + n2.getDate()
            console.log(d2)
            let obj = {
              id: _id,
              a: '',
              product: Name,
              Sdate: d,
              Edate: d2,
              Month:Month||'N/A',
              region:(region? region.Name:"N/A"),
              category: cat,
              ad: 'N',
              src: Img.Img,
              status: (
                <div>
                  <button type="button" className="btn btn-success" value={i} onClick={(e) => this.Edit(e.target.value)}>
                    <FiEdit onClick={(e) => this.Edit(e.target.parentNode.value)} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    value={i}
                    onClick={(e) => {
                      this.del(e.target.value)
                    }}
                  >
                    <FiTrash
                      onClick={(e) => {
                        this.del(e.target.parentNode.value)
                      }}
                    />
                  </button>
                </div>
              ),
            }
            rows.push(obj)
            i++
            // }
          })
          if (e === 1) {
            this.setState({ rows: rows, username: user_name, showAlert: false, msg: '', Subscription: dd })
          } else this.setState({ rows: rows, username: user_name, Subscription: dd })
        } else console.log('error23')
      })
      .catch((err) => {
        console.log('error',err)
      })
  }

  close = (e) => {
    this.setState({ showAlert: false, removed: true })
  }
  RemoveRow = () => {
    let obj = {
      username: this.state.username,
      id: this.state.rows[this.state.clicked].id,
    }

    axios
      .post('https://provider.beelicensed.com/providers/DeleteProduct', obj)
      .then((result) => {
        if (result.data.status === 200) {
          let caba = JSON.parse(localStorage.getItem('cat'))

          localStorage.setItem('cat', JSON.stringify(caba))

          this.UpdateRows(1)
        } else this.setState({ removed: false, msg: result.data.msg })
      })
      .catch((err) => {
        this.setState({ removed: false, msg: 'Network Error' })
      })
  }

  render() {
    let { title } = JSON.parse(localStorage.getItem('user-group'))
    let columnArray = column
    if (title === auth.UserGroupTitle.Poppingtime) columnArray = column2
    else if (title === auth.UserGroupTitle.Loyalswan) columnArray = column3
    const data = {
      columns: columnArray,
      rows: this.state.rows,
    }
    const group=title;
    return (
      <div>
        {this.state.Edit ? <Redirect to={auth.ProductFormRedirectRoute(group).UpdateForm} /> : null}

        <div className="editContainer">
          <div className="content">
            <div className="row">
              <div className="Form">
                <div className="card-header  ">
                  <h4 className="card-title AddHead">
                    Producten
                    <Link to={auth.ProductFormRedirectRoute(group).AddForm}>
                      <div className="AddLogo">
                        <FiPlusCircle />
                      </div>
                    </Link>
                  </h4>

                  <br />
                  <div className="UserRegular2"> Uw huidige abonnement eindigt op {' ' + this.state.Subscription}</div>
                </div>
                <div className=" TableScroll">
                  <MDBDataTable responsiveMd hover data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showAlert ? (
          <div className="swal2-container swal2-center swal2-shown">
            <div className="swal2-popup swal2-modal swal2-show" style={{ display: 'flex' }}>
              <div className="swal2-header">
                {/*----------------------------ALERT HEADER----------------------------- */}
                <div className="swal2-icon swal2-warning swal2-animate-warning-icon" style={{ display: 'flex' }}></div>

                {console.log(urls + this.state.rows[this.state.clicked].src)}
                <img
                  className="swal2-image"
                  src={urls + this.state.rows[this.state.clicked].src}
                  style={{ display: 'flex' }}
                ></img>

                {/*-----------------------------Alert MESSAGE-----------------------------------*/}

                <h2 className="swal2-title" id="swal2-title" style={{ display: 'flex' }}>
                  Product verwijderen
                </h2>

                {/*------------------------------ALERT TEXT CONTENT-------------------------------- */}
                <div className="swal2-content">
                  <div id="swal2-content" style={{ display: 'block' }}>
                    {
                      this.state.removed ? (
                        /*  console.log( this.state.rows[this.state.clicked].product+" "+ this.state.rows[this.state.clicked].region)*/
                        <div>
                          {' Weet je zeker dat u  ' +
                            this.state.rows[this.state.clicked].product +
                            ' uit ' +
                            this.state.rows[this.state.clicked].region +
                            ' wilt verwijderen?'}
                        </div>
                      ) : (
                        <div>{this.state.msg}</div>
                      )
                      /* console.log(this.state.msg)*/
                    }
                  </div>
                </div>

                {/* ---------------------------------------ALERT CLOSE---------------------------------*/}
                <button type="button" className="swal2-close" onClick={this.close} style={{ display: 'block' }}>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>×</font>
                  </font>
                </button>
              </div>
              {/*----------------------------ALERT BUTTON----------------------------------------- */}

              <div className="swal2-actions">
                <button
                  type="button"
                  onClick={this.RemoveRow}
                  className="swal2-confirm btn btn-warning btn-outline swal2-styled"
                  style={{
                    display: 'inline-block',
                    borderLeftColor: ' rgb(48, 133, 214)',
                    borderRightColor: 'rgb(48, 133, 214)',
                  }}
                >
                  verwijder
                </button>

                <button
                  type="button"
                  onClick={this.close}
                  className="swal2-cancel btn btn-info btn-outline swal2-styled"
                  style={{ display: 'inline-block' }}
                >
                  Annuleer
                </button>
              </div>

              {/*----------------------------Footer-------------------------------------------------*/}
              <div className="swal2-footer" style={{ display: 'block' }}></div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}
export default Product

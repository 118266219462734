import React from 'react'

import { Link, NavLink, Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import Success from '../../../Alert/Success'
import auth from '../../../Auth/auth'
import axios from 'axios'

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      region: '',
      status: false,
      codeVal: false,
      code: '',
      Success: false,
      Success2: false,
      msg: '',
      regions: [],
    }
  }
  componentDidMount() {
    let { user_name } = JSON.parse(localStorage.getItem('user'))

    axios
      .post('https://provider.beelicensed.com/providers/GetAllRegions', JSON.parse(localStorage.getItem('user')))
      .then((result) => {
        if (result.data.status === 200) {
          console.log(result.data.data)
          console.log(result.data.reg)
          if (result.data.reg && result.data.code)
            this.setState({
              username: user_name,
              region: result.data.reg,
              code: result.data.code,
              regions: result.data.data,
              codeVal: true,
            })
            else if (result.data.reg && !result.data.code)
            this.setState({
              username: user_name,
              region: result.data.reg,
              regions: result.data.data,
              codeVal: false,
            })
          else if (!result.data.code) {
            this.setState({
              username: user_name,
              region: result.data.reg,
              regions: result.data.data,
              codeVal: false,
              code: null,
            })
          } else {
            this.setState({
              username: user_name,
              regions: result.data.data,
              code: null,
            })
          }
        } else console.log('error 204')
      })
      .catch((err) => {
        console.log('axios error')
      })
  }

  SubmitForm = (event) => {
    event.preventDefault()

    axios
      .post('https://provider.beelicensed.com/providers/region', this.state)
      .then((result) => {
        if (result.data.status === 200) {
          // localStorage.clear();
          //  localStorage.setItem('user', JSON.stringify(result.data.user));
          this.setState({ status: true, Success: true, msg: '' })
        } else this.setState({ status: true, Success: false, msg: result.data.msg })
      })
      .catch((err) => {
        this.setState({ status: true, Success: false, msg: err.data.msg })
      })
  }

  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, Success2: true })
    else this.setState({ status: false, Success2: false })
  }
  render() {
    return (
      <div>
        {this.state.Success2 ? (
          <Redirect
            to={{
              pathname: '/Home',
              state: { User: this.state },
            }}
          />
        ) : (
          <div className="editContainer">
            <div className="content">
              <div className="row">
                <Form className="Form" onSubmit={this.SubmitForm}>
                  <div className="card-header ">
                    <h4 className="card-title">in welke regio bent uw actief?</h4>
                  </div>

                  <div className="card-body">
                    <Form.Row>
                      <select
                        className="browser-default custom-select"
                        value={this.state.region ? this.state.region : null}
                        onChange={(e) => {
                          let code
                          for (let k of this.state.regions) {
                            if (k._id === e.target.value) code = k.private
                            console.log(k.private)
                          }

                          console.log(code)
                          if (!code) this.setState({ region: e.target.value, codeVal: code, code: null })
                          else this.setState({ region: e.target.value, codeVal: code })
                        }}
                      >
                        <option default> Kies een regio</option>
                        {this.state.regions.map((el) => {
                          if (this.state.region === el._id)
                            return (
                              <option selected={true} value={el._id}>
                                {el.Name}
                              </option>
                            )
                          else return <option value={el._id}>{el.Name}</option>
                        })}
                      </select>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group>
                        {this.state.codeVal ? (
                          <Form.Control
                            type="text"
                            required
                            placeholder="code"
                            value={this.state.code}
                            onChange={(e) => {
                              this.setState({ code: e.target.value })
                            }}
                          />
                        ) : null}
                      </Form.Group>
                    </Form.Row>

                    <Button variant="primary" type="submit">
                      PROFIEL BJIWERKEN
                    </Button>
                    <Link to="/Home">
                      <Button variant="danger">ANNULEER</Button>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Edit

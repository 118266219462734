import React from 'react'

import { Link, Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import Success from '../../../Alert/Success'
import auth from '../../../Auth/auth'
import axios from 'axios'
class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      firstname: '',
      lastname: '',
      tele: '',
      email: '',
      company: '',
      postal: '',
      House: '',
      add: '',
      street: '',
      city: '',
      township: '',
      province: '',
      Country: '',
      billing: false,
      post2: '',
      house2: '',
      add2: '',
      street2: '',
      city2: '',
      township2: '',
      province2: '',
      country2: '',
      status: false,
      Success: false,
      Success2: false,
      msg: '',
    }
  }
  componentDidMount() {
    let {
      user_name,
      firstname,
      lastname,
      tele,
      email,
      company,
      postal,
      House,
      add,
      street,
      city,
      township,
      province,
      Country,
      billing,
      post2,
      house2,
      add2,
      street2,
      city2,
      township2,
      province2,
      country2,
    } = JSON.parse(localStorage.getItem('user'))

    this.setState({
      username: user_name,
      firstname: firstname,
      lastname: lastname,
      tele: tele,
      email: email,
      company: company,
      postal: postal,
      House: House,
      add: add,
      street: street,
      city: city,
      township: township,
      province: province,
      Country: Country,
      billing: billing,
      post2: post2,
      house2: house2,
      add2: add2,
      street2: street2,
      city2: city2,
      township2: township2,
      province2: province2,
    })
  }
  SubmitForm = (event) => {
    event.preventDefault()

    axios
      .post('https://provider.beelicensed.com/providers/AddInfo', this.state)
      .then((result) => {
        if (result.data.status === 200) {
          localStorage.clear()
          localStorage.setItem('user', JSON.stringify(result.data.user))
          this.setState({ status: true, Success: true })
        } else this.setState({ status: true, Success: false, msg: 'Somthing went wrong' })
      })
      .catch((err) => {
        this.setState({ status: true, Success: false, msg: err.data.msg })
      })
  }

  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, Success2: true })
    else this.setState({ status: false, Success2: false })
  }
  render() {
    return (
      <div>
        {this.state.Success2 ? (
          <Redirect
            to={{
              pathname: '/Home',
              state: { User: this.state },
            }}
          />
        ) : (
          <div className="editContainer">
            <div className="content">
              <div className="row">
                <Form className="Form" onSubmit={this.SubmitForm}>
                  <div className="card-header ">
                    <h4 className="card-title">bewerk profiel</h4>
                  </div>

                  <div className="card-body">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridFname">
                        <Form.Label>voornaam *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="voornaam"
                          value={this.state.firstname}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ firstname: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridLname">
                        <Form.Label>achternaam *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="achternaam"
                          value={this.state.lastname}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ lastname: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="HideMe" controlId="formGridLname"></Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>telefoon +31 (0) 61 222 3344 *</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="telefoon +31 (0) 61 222 3344 *"
                          value={this.state.tele}
                          success="right"
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ tele: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGride-mailadres *">
                        <Form.Label>e-mailadres *</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="e-mailadres *"
                          value={this.state.email}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ email: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} className="HideMe" controlId="formGridLname"></Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCompany">
                        <Form.Label>bedrijfsnaam *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={this.state.company}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ company: e.target.value })
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridLname" className="HideMe">
                        {' '}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridpostcode *">
                        <Form.Label>postcode *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={this.state.postal}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ postal: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="formGridCity">
                        <Form.Label>huisnummer *</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          value={this.state.House}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ House: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group controlId="formGridState">
                        <Form.Label> toevoeging</Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.add}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ add: e.target.value })
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="HideMe" controlId="formGridCity"></Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>straat </Form.Label>
                        <Form.Control
                          type="text"
                          value={this.state.street}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ street: e.target.value })
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>stad *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={this.state.city}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ city: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="HideMe" as={Col} controlId="formGridZip"></Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>gemeente *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={this.state.township}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ township: e.target.value })
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>provincie *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={this.state.province}
                          onChange={(e) => {
                            console.log(e.target.value)
                            this.setState({ province: e.target.value })
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>land * </Form.Label>
                        <Form.Control as="select" value="NEDERLAND">
                          <option selected value="NEDERLAND">
                            NEDERLAND
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        checked={this.state.billing}
                        label="factuuradres is NIET hetzelfde als het bedrijfsadres"
                        onChange={(e) => {
                          this.setState({ billing: e.target.checked })
                        }}
                      />
                    </Form.Group>

                    {this.state.billing ? (
                      <div>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridpostcode *">
                            <Form.Label>postcode *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={this.state.post2}
                              onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({ post2: e.target.value })
                              }}
                            />
                          </Form.Group>

                          <Form.Group controlId="formGridCity">
                            <Form.Label>huisnummer *</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              value={this.state.house2}
                              onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({ house2: e.target.value })
                              }}
                            />
                          </Form.Group>

                          <Form.Group controlId="formGridState">
                            <Form.Label> toevoeging</Form.Label>
                            <Form.Control
                              type="text"
                              value={this.state.add2}
                              onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({ add2: e.target.value })
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="HideMe" controlId="formGridCity"></Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>straat </Form.Label>
                            <Form.Control
                              type="text"
                              value={this.state.street2}
                              onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({ street2: e.target.value })
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>stad *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={this.state.city2}
                              onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({ city2: e.target.value })
                              }}
                            />
                          </Form.Group>

                          <Form.Group className="HideMe" as={Col} controlId="formGridZip"></Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>gemeente *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={this.state.township2}
                              onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({ township2: e.target.value })
                              }}
                            />
                          </Form.Group>
                          <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>provincie *</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              value={this.state.province2}
                              onChange={(e) => {
                                console.log(e.target.value)
                                this.setState({ province2: e.target.value })
                              }}
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>land * </Form.Label>
                            <Form.Control as="select" value="NEDERLAND">
                              <option>NEDERLAND</option>
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                      </div>
                    ) : null}
                    <Button variant="primary" type="submit">
                      PROFIEL BIJWERKEN
                    </Button>
                    <Link to="/Home">
                      <Button variant="danger">ANNULEER</Button>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Edit

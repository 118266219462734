import React from 'react'

import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import Axios from 'axios'

class Genral extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Subscription: null,
    }
  }

  render() {
    // let subscription = null;
    let Subscription = 0
    let status = false
    let { subscription, CurrentStatus } = JSON.parse(localStorage.getItem('user'))
    if (subscription) {
      let n = new Date(subscription.EndDate)
      let d = n.getFullYear() + '-' + n.getMonth() + '-' + n.getDate()
      Subscription = d
      status = subscription.CurrentStatus
    }
    // let

    return (
      <div>
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <Form className="Form" onSubmit={this.SubmitForm}>
                <div className="card-header ">
                  <h4 className="card-title"> bewerk algemene instellingen </h4>{' '}
                </div>
                <div className="card-body">
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridFname">
                      <div className="GenralText">
                        {' '}
                        {}{' '}
                        {status ? (
                          <div>
                            Uw huidige abonnement eindigt op{' '}
                            <strong className="DateText"> {' ' + Subscription} </strong>{' '}
                          </div>
                        ) : (
                          'Buy Subscription'
                        )}{' '}
                      </div>{' '}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Link to="/Home/Order">
                      <Button variant="primary">BESTELLEN </Button>{' '}
                    </Link>
                    <Link to="/Home">
                      <Button variant="danger">ANNULEER </Button>
                    </Link>
                  </Form.Row>
                  <Form.Row className="FormClass2"></Form.Row>
                  <Form.Row></Form.Row>{' '}
                </div>{' '}
              </Form>
            </div>{' '}
          </div>{' '}
        </div>
      </div>
    )
  }
}
export default Genral

import axios from 'axios'
import 'bootstrap-css-only/css/bootstrap.min.css'
import { MDBBtn, MDBInput } from 'mdbreact'
import 'mdbreact/dist/css/mdb.css'
import React from 'react'
import { Link } from 'react-router-dom'
import Success from '../Alert/Success'
import './Login.css'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      company: '',
      mail: '',
      post: '',
      House: '',
      Country: '',
      usergroup: '',
      password: '',
      Confirm: '',
      msg: '',
      Success: false,
      status: false,
      options: [
        {
          Title: '',
          Id: '',
        },
      ],
    }
  }
  componentDidMount() {
    axios
      .get('https://provider.beelicensed.com/providers/GetAllUsergroups')
      .then((res) => {
        if (res.data.status === 200) {
          let collection = res.data.data

          let arr = []
          for (let km = 0; km < collection.length; km++) {
            let obj = {
              Title: '',
              Id: '',
            }

            obj.Title = collection[km].title
            obj.Id = collection[km]._id

            arr.push(obj)
          }
          console.log(arr)
          this.setState({ options: arr })
        }

        //this.setState({data:res.data.data});
        else console.log('no result')
        // this.setState({status:true,Success:false,msg:res.data.msg});
      })
      .catch((err) => {
        console.log('error')
      })
  }
  SubmitForm = (event) => {
    event.preventDefault()
    const { bool, msg } = this.FormValidate(this.state)
    if (!bool) this.setState({ status: true, Success: false, msg: msg })
    else {
      console.log(this.state)

      axios
        .post('https://provider.beelicensed.com/providers/register', this.state)
        .then((res) => {
          if (res.data.status === 200)
            this.setState({
              status: true,
              Success: true,
              msg: ' Your account has bee created',
            })
          else this.setState({ status: true, Success: false, msg: res.data.msg })
        })
        .catch((err) => {
          this.setState({ status: true, Success: false, msg: err.data.msg })
        })
    }
  }
  FormValidate = (val) => {
    let status = {
      bool: true,
      msg: '',
    }
    let { username, company, mail, post, House, Country, usergroup, password, Confirm } = val
    if (!username || !company || !mail || !post || !House || !Country || !usergroup || !password || !Confirm) {
      console.log(val)
      status.bool = false
      status.msg = 'fields cannot be empty'
      return status
    } else if (password.length < 9 || Confirm.length < 9) {
      status.bool = false
      status.msg = 'Password length should be greater than 8'
      return status
    } else if (password != Confirm) {
      status.bool = false
      status.msg = 'Passwords donot match'
      return status
    } else if (post.length < 6) {
      status.bool = false
      status.msg = 'Wrong post'
      return status
    } else {
      status.bool = true
      return status
    }
  }

  HideStatus = (e) => {
    this.setState({ status: false, msg: '', Success: false })
  }

  render() {
    let options = this.state.options

    return (
      <div className="cont">
        <div className="LoginInner">
          <div className="coupon_image"></div>

          <form className="LoginForm" onSubmit={this.SubmitForm}>
            <br />
            <br />

            <MDBInput
              label="gebruikersnaam"
              icon="user"
              group
              type="Text"
              validate
              error="wrong"
              success="right"
              required
              onChange={(e) => {
                this.setState({ username: e.target.value })
              }}
            />

            <MDBInput
              label="bedrijfsnaam"
              icon="building"
              group
              type="Text"
              validate
              error="wrong"
              success="right"
              required
              onChange={(e) => {
                this.setState({ company: e.target.value })
              }}
            />

            <MDBInput
              label="e-mailadres"
              icon="envelope"
              group
              type="email"
              validate
              error="wrong"
              success="right"
              required
              onChange={(e) => {
                this.setState({ mail: e.target.value })
              }}
            />

            <MDBInput
              label="postcode"
              icon="map"
              group
              type="Text"
              minLength="6"
              maxLength="6"
              validate
              error="wrong"
              success="right"
              required
              onChange={(e) => {
                this.setState({ post: e.target.value })
              }}
            />

            <MDBInput
              label="huisnummer"
              icon="home"
              group
              type="number"
              validate
              error="wrong"
              success="right"
              required
              onChange={(e) => {
                this.setState({ House: e.target.value })
              }}
            />

            <select
              className="browser-default custom-select"
              required
              onChange={(e) => {
                this.setState({ Country: e.target.value })
              }}
            >
              <option selected value="-1" disabled={true}>
                Land
              </option>
              <option value="Nederland">Nederland</option>
            </select>
            <br></br>
            <br />
            <select
              className="browser-default custom-select"
              placeholder="gebruikersgroep"
              required
              onChange={(e) => {
                this.setState({ usergroup: e.target.value })
              }}
            >
              <option selected value="-1" disabled={true}>
                gebruikersgroep
              </option>
              {options.map((val) => {
                return <option value={val.Id}>{val.Title}</option>
              })}
            </select>
            <MDBInput
              label="wachtwoord"
              icon="lock"
              group
              type="password"
              minlength="9"
              validate
              required
              onChange={(e) => {
                this.setState({ password: e.target.value })
              }}
            />

            <MDBInput
              label="bevestig wachtwoord"
              icon="lock"
              group
              minlength="9"
              type="password"
              validate
              required
              onChange={(e) => {
                this.setState({ Confirm: e.target.value })
              }}
            />

            <div className="text-center mb-3">
              <MDBBtn type="Submit" gradient="blue" rounded className="btn-block z-depth-1a">
                registreren
              </MDBBtn>
            </div>
          </form>
          <div className="form-footer footer-signin">
            <div align="center">
              <p className="text-info">
                <small>
                  {' '}
                  &nbsp;
                  <span className="bracket-link">
                    [&nbsp;<Link to="/"> Inloggen</Link>&nbsp;]
                  </span>
                </small>
              </p>
              © auteursrecht{' '}
              <Link to="/" target="_blank">
                couponcard.nl
              </Link>{' '}
              2016, 2020{' '}
            </div>
          </div>
        </div>
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Login

import React from 'react'
import head from './logos/head'
import popigumtimeLogo from './logos/popigumtimeLogo'
import wgp from './logos/popigumtimeLogo'
import walkdrinks from './logos/walkdrinks'
import banned1 from './logos/banned1'
import loyalswan from './logos/loyalswan'
import pantrywize from './logos/pantrywize'
import slothsy from './logos/slothsy'
import testally from './logos/testally'
import { MDBDataTable } from 'mdbreact'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import { FiPrinter } from 'react-icons/fi'
import './Success.css'
import axios from 'axios'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
//import {createCanvas, loadImage} from'canvas';

pdfMake.vfs = pdfFonts.pdfMake.vfs

const column = [
  {
    label: 'No',
    field: 'no',
    sort: 'asc',
    width: 50,
  },
  {
    label: 'Faktuurnummer',
    field: 'invoice_no',
    sort: 'asc',
    width: 200,
  },

  {
    label: 'Ordernummer',
    field: 'order',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'Omschrijving',
    field: 'Desc',
    sort: 'asc',
    width: 250,
  },
  {
    label: '',
    field: 'status',
    width: 30,
  },
]

class Invoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAlert: false,
      Print: false,

      columns: column,
      clicked: -1,
      rows: [
        {
          id: 1,
          no: '',
          invoice_no: '',
          order: '',
          Desc: '',

          status: (
            <div>
              <button type="button" className="btn btn-blue" onClick={this.Print.bind(this, 1)}>
                <FiPrinter className="PrintLogo" />
              </button>
            </div>
          ),
        },
      ],
      
      RowsInfo: [],
      userInfo: null,
    }
  }
  componentDidMount() {
    let { user_name } = JSON.parse(localStorage.getItem('user'))
    let userJSON = JSON.parse(localStorage.getItem('user'))
    console.log(localStorage.getItem('user-group'),"localStorage.getItem('user-group')")
    console.log("userJSON", userJSON)
    let obj1 = {
      username: user_name,
    }
    axios
      .post('https://provider.beelicensed.com/providers/GetInvoices', obj1)
      .then((result) => {
        if (result.data.status === 200) {
          let Invoices = result.data.Invoice
          let i = 0
          let rows = []
          let Product = []
          Invoices.map((element) => {
            let { _id, Invoice_Number, OrderNumber, Description, productInfo, Period } = element
            let obj = {
              id: _id,
              no: i + 1,
              invoice_no: Invoice_Number,
              order: OrderNumber,
              Desc: Description,
              Period: Period,
              status: (
                <div>
                  <button type="button" className="btn btn-blue" value={i} onClick={(e) => this.Print(e.target.value)}>
                    <FiPrinter className="PrintLogo" onClick={(e) => this.Print(e.target.parentNode.value)} />
                  </button>
                </div>
              ),
            }
            rows.push(obj)
            Product.push(element)
            i++
          })
          this.SetRows(rows, Product, result.data.userInfo)
        } else console.log('No data')
      })
      .catch((err) => {
        console.log('Error')
      })

      let userId = {
        providerId:localStorage.getItem('user_Id')
      }

      console.log("userId", userId)

      axios.post('http://provider.beelicensed.com/providers/getPaidProductCommisionByProviderId', userId)
      .then((result) => {
        let Invoices = result.data.data
        let i = 0
        let rows = []
        let Product = []
        Invoices.map((element) => {
          let { _id, Invoice_Number, OrderNumber, Description, productInfo, Period, BUpaymentId } = element
          let obj = {
            id: _id,
            no: i + 1,
            invoice_no: BUpaymentId,
            order: BUpaymentId,
            Desc: 'Description',
            Period: 'Period',
            status: 'Paid',
          }
          rows.push(obj)
          Product.push(element)
          i++
        })
        this.SetRows(rows, Product, result.data.userInfo)
      })
      .catch((err)=> {
        console.log("err", err)
      })
  }

  SetRows = (r, p, userInfo) => {
    console.log("r", r);
    console.log("p", p);
    console.log("userInfo", userInfo);
    this.setState({ rows: r, RowsInfo: p, userInfo: userInfo })
  }
  table = (data, columns) => {
    return {
      table: {
        headerRows: 1,
        body: this.buildTableBody(data, columns),
      },
    }
  }
  buildTableBody = (data, columns) => {
    var body = []

    body.push(columns)

    data.forEach(function (row) {
      var dataRow = []

      columns.forEach(function (column) {
        dataRow.push(row[column].toString())
      })

      body.push(dataRow)
    })

    return body
  }
  getImageHead = (appName) =>{
    if (appName === "wgp" || appName === "WGP" || appName === "WGP Plaza" || appName === "WGPplaza") return wgp;
    if (appName === "Walkdrinks" || appName === "walkdrinks") return walkdrinks;
    if (appName === "Banned1" || appName === "banned1") return banned1;
    if (appName === "Loyalswan" || appName === "loyalswan") return loyalswan;
    if (appName === "Pantrywize" || appName === "pantrywize") return pantrywize;
    if (appName === "Slothsy" || appName === "slothsy") return slothsy;
    if (appName === "Testally" || appName === "testally") return testally;
    if (appName === "Poppingtime" || appName === "poppingtime") return popigumtimeLogo;

    return head;
  }
  getEmailandName = (appName) =>{
    if (appName === "wgp" || appName === "WGP" || appName === "WGP Plaza" || appName === "WGPplaza") return {name:"WGP",email:"info@wgp.com"};
    if (appName === "Walkdrinks" || appName === "walkdrinks") return {name:"Walkdrinks",email:"info@Walkdrinks.com"};
    if (appName === "Banned1" || appName === "banned1") return {name:"Banned1",email:"info@Banned1.com"};
    if (appName === "Loyalswan" || appName === "loyalswan") return {name:"Loyalswan",email:"info@Loyalswan.com"};
    if (appName === "Pantrywize" || appName === "pantrywize") return {name:"Pantrywize",email:"info@Pantrywize.com"};
    if (appName === "Slothsy" || appName === "slothsy") return {name:"Slothsy",email:"info@Slothsy.com"};
    if (appName === "Testally" || appName === "testally") return {name:"Testally",email:"info@Testally.com"};
    if (appName === "Poppingtime" || appName === "poppingtime") return {name:"Poppingtime",email:"info@Poppingtime.com"};

    return {name:"Beelicensed",email:"info@beelicensed.com"};
  }
  async Print(e) {
    let usergroup = JSON.parse(localStorage.getItem('user-group'));
    if (e) {
      let { user_name, postal, House, city, province, street, township, company, Country } = this.state.userInfo
      let {
        id, //const year = 31536000000;
        no,
        invoice_no,
        order,
        Desc,
      } = this.state.rows[e]
      let OrderNumber=order;
      let n = new Date(OrderNumber)
      let d =
        n.getFullYear() +
        '-' +
        (n.getMonth() + 1) +
        '-' +
        n.getDate() +
        '  ' +
        n.getHours() +
        ':' +
        n.getMinutes() +
        ':' +
        n.getSeconds()
     
      if (this.state.rows[e].Desc === 'Commission cost') {

        let {Total,vat,totalCommission } = this.state.RowsInfo[e];
        let obj = []
        for (let x of this.state.RowsInfo[e].productInfo) {
          let dt= new Date( x.ArrivalTime);
          let arrival = dt.getFullYear() +
          '-' +
          (dt.getMonth() + 1) +
          '-' +
          dt.getDate() +
          '  ' +
          dt.getHours() +
          ':' +
          dt.getMinutes() +
          ':' +
          dt.getSeconds()
          let dat= new Date(x.createdAt);
          let createdAt = dat.getFullYear() +
          '-' +
          (dat.getMonth() + 1) +
          '-' +
          dat.getDate() +
          '  ' +
          dat.getHours() +
          ':' +
          dat.getMinutes() +
          ':' +
          dat.getSeconds()
          let obj2 = {
            Product: x.ProductId.Name,
            createdAt:createdAt,
            ArrivalTime:arrival,
            Aantal: x.BookedSlots,
            User: x.CU.username,
            Total: x.ProductId.SubCat.commission,
          }
        
          
          obj.push(obj2)
        }

        var docDefinition = {
          content: {
            margin: [0, 100, 0, 50],
            columns: [
              {
                stack: [
                  { text: 'factuur', style: 'header' },
                  { text: `${this.state.rows[e].invoice_no}`, style: 'SUBheader' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: `Betaaldatum:${d}` },
                  { text: ` Ordernummer:${ OrderNumber}` },
                  { text: ' Status: paid' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: `${company}` },
                  { text: `tav: ${user_name}` },
                  { text: `${street} ${House}` },
                  { text: ` ${postal} ${city}` },
                  { text: ` ${Country}` },
                  { text: `period:` },
                  { text: '  ' },
                  this.table(obj, ['Product', 'createdAt','ArrivalTime', 'Aantal', 'User', 'Total']),
                  { text: '  ' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: '  ' },
                  {
                    text: `Subtotaal: -                                                                                                             ${totalCommission}`,
                  },
                  {
                    text: `BTW 21%:                                                                                                               ${vat}`,
                  },

                  {
                    text: `Totaal:                                                                                                                     ${Total}`,
                  },
                ],
              },
            ],
          },

          header: [{ image: this.getImageHead(usergroup.title), width: 600 }],

          footer: [
            {
              text:
                'Slothsy - Wijngaardenlaan 17 - 2252 XJ Voorschoten  Kvk 28104652 - BTW NL818216864B02 -                                                                 bankreknr. NL37 INGB 0004 6739 78 tnv Bureau Integra BV - e info@slothsy.com',
              style: 'anotherStyle',
            },
          ],

          styles: {
            header: {
              fontSize: 22,
              bold: true,
              italics: true,
            },
            SUBheader: {
              fontSize: 22,
              italics: true,
            },
            defaultStyle: {
              lineHeight: 2,
              font: 'Courier',
            },
            anotherStyle: {
              italics: true,
              alignment: 'center',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
      } else {
        var docDefinition = {
          content: {
            margin: [0, 100, 0, 50],
            columns: [
              {
                stack: [
                  { text: 'factuur', style: 'header' },
                  { text: `${this.state.rows[e].invoice_no}`, style: 'SUBheader' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: `Betaaldatum:${d}` },
                  { text: ` Ordernummer:${ OrderNumber}` },
                  { text: ' Status: paid' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: `${company}` },
                  { text: `tav: ${user_name}` },
                  { text: `${street} ${House}` },
                  { text: ` ${postal} ${city}` },
                  { text: ` ${Country}` },
                  { text: `Period:    ${this.state.rows[e].Period} ` },
                  { text: '  ' },
                  {
                    text: `${this.state.rows[e].Desc} -                                                                                                        ${(this.state.RowsInfo[e].totalCommission).toFixed(2)}`,
                  },
                  { text: '  ' },
                  { text: '  ' },
                  { text: '  ' },
                  { text: '  ' },
                  {
                    text: `Subtotaal:                                                                                                               ${(this.state.RowsInfo[e].totalCommission).toFixed(2)}`,
                  },
                  {
                    text: `BTW 21%:                                                                                                                    0.00`,
                  },

                  {
                    text: `Totaal:                                                                                                                     ${(this.state.RowsInfo[e].totalCommission).toFixed(2)}`,
                  },
                ],
              },
            ],
          },
          header: [{ image: this.getImageHead(usergroup.title), width: 250,margin:20 }],

          footer: [
            {
              text:
                `${this.getEmailandName(usergroup.title).name} - Wijngaardenlaan 17 - 2252 XJ Voorschoten  Kvk 28104652 - BTW NL818216864B02 -                                                                 bankreknr. NL37 INGB 0004 6739 78 tnv Bureau Integra BV - e ${this.getEmailandName(usergroup.title).email}`,
              style: 'anotherStyle',
            },
          ],

          styles: {
            header: {
              fontSize: 22,
              bold: true,
              italics: true,
            },
            SUBheader: {
              fontSize: 22,
              italics: true,
            },
            defaultStyle: {
              lineHeight: 2,
              font: 'Courier',
            },
            anotherStyle: {
              italics: true,
              alignment: 'center',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
      }
    }
  }

  render() {
    const data = {
      columns: this.state.columns,
      rows: this.state.rows,
    }
    return (
      <div>
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <div className="Form">
                <div className="card-header  ">
                  <h4 className="card-title ">Invoices</h4>
                </div>
                <div className=" TableScroll">
                  <MDBDataTable responsiveMd hover striped data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Invoice

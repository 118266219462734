import React from 'react'
import './Badge.css'
import './Success.css'

class Success extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Header: this.props.status,
      Message: this.props.msg,
    }
  }
  render() {
    return (
      <div className="swal2-container swal2-center swal2-shown">
        <div className="swal2-popup swal2-modal swal2-show" style={{ display: 'flex' }}>
          <div className="swal2-header">
            {/*----------------------------ALERT HEADER----------------------------- */}
            {this.state.Header ? (
              <div className="swal2-icon swal2-success" style={{ display: 'block' }}>
                <div className="swal2-success-circular-line-left" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
                  <span className="swal2-success-line-tip"></span>
                  <span className="swal2-success-line-long"></span>

                  <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
                  <div
                    className="swal2-success-circular-line-right"
                    style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  ></div>
                </div>
              </div>
            ) : (
              <div className="swal2-icon swal2-error" style={{ display: 'block' }}>
                <div className="swal2-error-circular-line-left" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
                  <span className="swal2-x-mark">
                    <span className="swal2-x-mark-line-left"></span>
                    <span className="swal2-x-mark-line-right"></span>
                  </span>
                </div>
              </div>
            )}

            {/*-----------------------------Alert MESSAGE-----------------------------------*/}
            <h2 className="swal2-title" id="swal2-title" style={{ display: 'block' }}>
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>{this.state.Header ? 'Succes' : 'Foutmelding'}</font>
              </font>
            </h2>
            {/*------------------------------ALERT TEXT CONTENT-------------------------------- */}

            <div className="swal2-content">
              <div id="swal2-content" style={{ display: 'block' }}>
                <font style={{ verticalAlign: 'inherit' }}>
                  <font style={{ verticalAlign: 'inherit' }}>{this.state.Message}</font>
                </font>
              </div>
            </div>
            {/* ---------------------------------------ALERT CLOSE---------------------------------*/}
            <button type="button" className="swal2-close" onClick={this.props.OnClose} style={{ display: 'block' }}>
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>×</font>
              </font>
            </button>
          </div>
          {/*----------------------------ALERT BUTTON----------------------------------------- */}
          <div className="swal2-actions">
            <button
              type="button"
              className="swal2-confirm swal2-styled"
              onClick={this.props.OnClose}
              style={{
                display: 'inline-block',
                borderLeftColor: 'rgb(48, 133, 214)',
                borderRightColor: 'rgb(48, 133, 214)',
              }}
            >
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>OK</font>
              </font>
            </button>
            <button type="button" className="swal2-cancel swal2-styled" style={{ display: 'none' }}>
              <font style={{ verticalAlign: 'inherit' }}>
                <font style={{ verticalAlign: 'inherit' }}>Cancel</font>
              </font>
            </button>
          </div>

          <div className="swal2-footer" style={{ display: 'block' }}></div>
        </div>
      </div>
    )
  }
}
export default Success

import React, { useEffect, useState } from 'react';
import { useRouteMatch, Link } from "react-router-dom";
import axios from 'axios';
import './PaymentSuccess.css'


function PaymentSuccess() {
    const [message, setMessage] = useState("")
    const [description, setDescription] = useState("")
    const [paymentStatus, setPaymentStatus] = useState(false)
    const match = useRouteMatch();
    useEffect(() => {
        axios.get(`http://provider.beelicensed.com/providers/getProductCommisionById/${match.params.id}`).then((response) => {
            let res = JSON.parse(JSON.stringify(response));
            if (res.data.data && res.data.data.BUpaymentStatus === "paid") {
                setMessage("Payment successful!")
                setDescription("We are delighted to inform you that we received your payments")
                setPaymentStatus(true)
            }
            else {
                setMessage("Payment unsuccessful!")
                setDescription("We are sorry to inform you that your payment has been declined due to some reason. Please try again.")
                setPaymentStatus(false)
            }

        })
        var reservedIds = localStorage.getItem("reservationsIdStr")?.split(",");
        reservedIds = reservedIds?.splice(0, reservedIds.length - 1);
        if (reservedIds) {
            reservedIds.forEach(element => {
                axios.post(`http://provider.beelicensed.com/providers/PayReservation`, { reservationId: element, paymentStatus: true }).then((response) => {
                    //ngrok
                    let res = JSON.parse(JSON.stringify(response));
                })
            });
        }
    }, []);
    return (
        <div>
            <div class="success-page">
                <img src="http://share.ashiknesin.com/green-checkmark.png" class="center" alt="" />
                <h2 className={paymentStatus ? "paid" : "not-paid"}>{message}</h2>
                <p>{description}</p>
                {paymentStatus ?
                    <Link to="/vouchers" class="btn-view-orders">View Orders</Link>
                    : null}
                <Link to="/">Continue Shopping</Link>
            </div>
        </div>
    )
}
export default PaymentSuccess;
import React from 'react'
import PrivateRoute from './private/privateRoute'
import Login from './Forms/Login'
import Register from './Forms/Register'
import Forgot from './Forms/Forgot'
import Nav from './Home/elements/NavBar/Nav'
import Welcome from './Home/elements/Welcome/Welcome'
import Edit from './Home/elements/Edit/edit'
import Pass from './Home/elements/Password/Pass'
import PaymentSuccess from './PaymentSuccess/PaymentSuccess'
import Bank from './Home/elements/BankInfo/Bank'
import Region from './Home/elements/Region/Region'
import Cat from './Home/elements/Categories'
import Product from './Home/elements/Products/Product'
import Update from './Home/elements/Products/Update'
import Invoice from './Home/elements/Invoicees/invoice'
import Res from './Home/elements/Invoicees/Reservations'
import Genral from './Home/elements/General/genral'
import Pay from './Home/elements/General/Pay'
import ReservationPay from './Home/elements/General/ReservationPay'
import Calender from './Home/elements/Products/calender'
import Stock from './Home/elements/Products/Stock'
import LoyalswnaForm from './Home/elements/Products/LoyalswanForm'
import Footer from './Home/Footer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/" exact>
              <Login ValidationLogin={this.ValidateLogin} />
            </Route>
            <Route path="/forgot">
              <Forgot Validation={this.ValidateUser} />
            </Route>
            <Route path="/register" component={Register} />
            <PrivateRoute
              exact
              path="/Home"
              component={() => (
                <div>
                  <Nav />
                  <Welcome />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/BewerkProfiel"
              component={() => (
                <div>
                  <Nav />
                  <Edit UpdateProfile={this.UpdateInfo} />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Wachtwoord"
              component={() => (
                <div>
                  <Nav />
                  <Pass ChangePass={this.ChangeAccountPass} />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Bankinformatie"
              component={() => (
                <div>
                  <Nav />
                  <Bank ChangeBank={this.UpdateBank} />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Regio"
              component={() => (
                <div>
                  <Nav />
                  <Region UpdateRegion={this.UpdateRegInfo} />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Categorieën"
              component={() => (
                <div>
                  <Nav />
                  <Cat UpdateCat={this.UpdateCatInfo} />
                </div>
              )}
            />
            <PrivateRoute
              path="/verification/:id"
              component={() => (
                <div>
                  <Nav />
                  <PaymentSuccess />
                </div>
              )}
            />
            <PrivateRoute
              path="/Home/Products"
              component={() => (
                <div>
                  <Nav />
                  <Product Remove={this.RowRemove} />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Update"
              component={() => (
                <div>
                  <Nav />
                  <Update />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/NewProduct"
              component={() => (
                <div>
                  <Nav />
                  <Update />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Invoices"
              component={() => (
                <div>
                  <Nav />
                  <Invoice />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Res"
              component={() => (
                <div>
                  <Nav />
                  <Res />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/genral"
              component={() => (
                <div>
                  <Nav />
                  <Genral />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Order"
              component={() => (
                <div>
                  <Nav />
                  <Pay />
                </div>
              )}
            />
            <PrivateRoute
              path="/Home/Pay"
              component={() => (
                <div>
                  <Nav />
                  <Pay />
                </div>
              )}
            />
            <PrivateRoute
              path="/Home/PayComission/:price"
              component={() => (
                <div>
                  <Nav />
                  <ReservationPay />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Calendar"
              component={() => (
                <div>
                  <Nav />
                  <Calender />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/AddCalendar"
              component={() => (
                <div>
                  <Nav />
                  <Calender />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/Stock"
              component={() => (
                <div>
                  <Nav />
                  <Stock />
                </div>
              )}
            />

            <PrivateRoute
              path="/Home/NewProductLS"
              component={() => (
                <div>
                  <Nav />
                  <LoyalswnaForm />
                </div>
              )}
            />
            <PrivateRoute
              path="/Home/EditProductLS"
              component={() => (
                <div>
                  <Nav />
                  <LoyalswnaForm />
                </div>
              )}
            />
          </Switch>
        </Router>
        <Footer />
      </div>
    )
  }
}
export default App

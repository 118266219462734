import React from 'react'
import Success from '../../../Alert/Success'
import { Link, Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import auth from '../../../Auth/auth'
import Select from 'react-select'
import { MDBTable, MDBTableBody } from 'mdbreact'
import axios from 'axios'

const options = [
  { value: 60, label: 60 },
  { value: 30, label: 30 },
  { value: 20, label: 20 },
  { value: 15, label: 15 },
]
let TableRes = []
for (let i = 0; i <= 100; i++) {
  let obj = {
    value: i,
    label: i,
  }
  if (i === 0) {
    obj = {
      value: 0,
      label: '0',
    }
  } else
    obj = {
      value: i,
      label: i,
    }
  TableRes.push(obj)
}
let Option2 = []
let Option3 = []

class Calender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      id: '',
      Name: '',
      StartDate: '',
      EndDate: '',
      interval: 60,
      openingTime: 0,
      ClosingTime: 86400000,
      Autofill: false,
      calander: [
        {
          ResDate: 0,
          MaxReservation: 9,

          Slots: [
            {
              SlotOpeningTime: 0,
              ResLeft: 9,
              SlotReservations: 0,
            },
          ],
        },
      ],
      status: false,
      Success: false,
      Success2: false,
      msg: '',
    }
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'))
    let path = window.location.pathname
    if (path === '/Home/Calendar') {
      let m = JSON.parse(localStorage.getItem('EditProductId'))
      let obj = {
        id: m.id,
        username: user.user_name,
      }
      axios
        .post('https://provider.beelicensed.com/providers/GetCalander', obj)
        .then((result) => {
          if (result.data.status === 200) {
            let info = result.data.calander
            console.log(info)
            let { user_name } = user
            let { _id, Name, StartDate, EndDate, calander, interval, openingTime, ClosingTime } = info

            this.setState({
              interval: interval,
              openingTime: openingTime,
              ClosingTime: ClosingTime,
              username: user_name,
              id: _id,
              StartDate: StartDate,
              EndDate: EndDate,
              Name: Name,
              calander: calander,
            })
          } else this.setState({ status: true, Success: false, msg: result.data.msg })
        })
        .catch((err) => {
          console.log(err)
          this.setState({ status: true, Success: false, msg: 'seems like network is slow' })
        })
    }
  }

  ClosingTime = (e) => {
    console.log(e)
    this.setState({ ClosingTime: e.value })
    //this.setState({ClosingTime:e.value,calander:TableCol});
  }

  MakeCal = (e) => {
    if (this.state.ClosingTime && this.state.openingTime && this.state.interval) {
      let interval = 3600000
      if (this.state.interval === 60) {
        interval = 3600000
      }
      if (this.state.interval === 30) interval = 1800000
      if (this.state.interval === 20) interval = 1200000
      if (this.state.interval === 15) interval = 900000

      let TableCol = []
      for (let date = this.state.StartDate; date <= this.state.EndDate; date += 86400000) {
        let obj = {
          ResDate: date,
          MaxReservation: 9,
          Slots: [],
        }
        for (let index = this.state.openingTime; index <= this.state.ClosingTime; index += interval) {
          let obj2 = {
            SlotOpeningTime: index,
            ResLeft: 9,
            SlotReservations: 0,
          }
          //     console.log(obj2);
          obj.Slots.push(obj2)
        }

        TableCol.push(obj)
      }
      // console.log(TableCol);
      this.setState({ calander: TableCol })
    } else {
      this.setState({ status: true, Success: false, msg: 'Please select all the fields.' })
    }
  }
  OpeningTime = (e) => {
    console.log(e)
    this.setState({ openingTime: e.value })
  }

  SubmitForm = (event) => {
    event.preventDefault()

    this.setState({ status: true, Success: true, msg: '' })
    axios
      .post('https://provider.beelicensed.com/providers/AddCalander', this.state)
      .then((result) => {
        if (result.data.status === 200) {
          localStorage.setItem('user', JSON.stringify(result.data.user))
          this.setState({ status: true, Success: true, msg: '' })
        } else this.setState({ status: true, Success: false, msg: result.data.msg })
      })
      .catch((err) => {
        this.setState({ status: true, Success: false, msg: err.data.msg })
      })
  }

  HideStatus = (e) => {
    console.log('we are in Hide status  ')

    this.setState({ status: false, Success2: true })
  }
  CloseMe = () => {
    console.log(this.state.msg)
    if (!this.state.msg) this.setState({ status: false, Success2: true })
    else {
      this.setState({ status: false, Success2: false })
    }
  }
  CheckCalander = () => {
    let sum = 0
    let cal = this.state.calander
    for (let index = 0; index < cal.length; index++) {
      for (let index2 = 0; index2 < cal[index].Slots.length; index2++) {
        sum += cal[index].Slots[index2].SlotReservations

        if (sum > cal[index].MaxReservation) return false
      }
      sum = 0
    }
    return true
  }
  render() {
    let success = this.state.Success
    let i = -1
    let interval = 3600000
    if (this.state.interval === 60) {
      interval = 3600000
    }
    if (this.state.interval === 30) interval = 1800000
    if (this.state.interval === 20) interval = 1200000
    if (this.state.interval === 15) interval = 900000
    Option2 = []
    for (let duration = 0; duration <= 86400000 - interval; duration += interval) {
      var milliseconds = parseInt((duration % 1000) / 100),
        seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24)

      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let obj = {
        value: duration,
        label: hours + ':' + minutes,
      }
      Option2.push(obj)
    }
    Option3 = []
    for (
      let duration = this.state.openingTime + interval;
      duration <= 86400000 + this.state.openingTime;
      duration += interval
    ) {
      var minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24)

      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      let obj = {
        value: duration,
        label: hours + ':' + minutes,
      }
      Option3.push(obj)
    }

    let Open = Option2[0]
    let Close = Option3[0]
    let Interval = options[0]
    for (let x of Option2) {
      if (x.value === this.state.openingTime) Open = x
    }
    for (let x of Option3) {
      if (x.value === this.state.ClosingTime) Close = x
    }
    for (let x of options) {
      if (x.value === this.state.interval) Interval = x
    }

    Interval = {
      value: this.state.interval,
      label: this.state.interval,
    }
    return (
      <div>
        {this.state.status ? (
          <div>
            {this.state.Success ? (
              <Success OnClose={this.HideStatus} msg={this.state.msg} status={true} />
            ) : (
                <Success OnClose={this.CloseMe} msg={this.state.msg} status={false} />
              )}
          </div>
        ) : null}
        {this.state.Success2 ? <Redirect to="/Home/Update" /> : null}
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <Form className="Form" onSubmit={this.SubmitForm}>
                <div
                  className="card-header"
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <h4 className="card-title1">{this.state.Name}</h4>
                  <br />
                  <h4 className="card-title">
                    Stocksettings
                    <br />
                  </h4>
                </div>

                <div className="card-body">
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>Kalender in intervallen van</Form.Label>
                      <Select
                        value={Interval}
                        onChange={(e) => {
                          console.log(e)
                          this.setState({ interval: e.value })
                        }}
                        options={options}
                      />
                      <Form.Label>minuten weergeven</Form.Label>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Openingstijden-van</Form.Label>
                      <Select value={Open} onChange={this.OpeningTime} options={Option2} />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>tot</Form.Label>
                      <Select value={Close} defaultValue={Option3[0]} onChange={this.ClosingTime} options={Option3} />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    {this.state.calander.length > 6 ? (
                      <Form.Group as={Col}>
                        <Button
                          variant="primary"
                          onClick={(e) => {
                            // let calander=this.state.calander[0];
                            let cal = this.state.calander
                            for (let index = 7; index < cal.length; index++) {
                              cal[index].MaxReservation = cal[parseInt(index % 7)].MaxReservation
                              // console.log(parseInt((index%7)))
                              //  cal[index].Slots=cal[parseInt((index%7))].Slots;
                              for (let k = 0; k < cal[index].Slots.length; k++) {
                                cal[index].Slots[k].SlotReservations =
                                  cal[parseInt(index % 7)].Slots[k].SlotReservations
                                cal[index].Slots[k].ResLeft = cal[parseInt(index % 7)].Slots[k].ResLeft
                              }
                            }

                            this.setState({ calander: cal })
                          }}
                        >
                          Repeat 1st week settings
                        </Button>
                      </Form.Group>
                    ) : null}
                    <Form.Group as={Col}></Form.Group>
                    <Form.Group as={Col}></Form.Group>
                    <Form.Group as={Col}>
                      <Button variant="primary" onClick={this.MakeCal}>
                        Adjust Calendar
                      </Button>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <div className="table-responsive TableScroll">
                      <MDBTable>
                        <MDBTableBody>
                          {this.state.calander.map((el) => {
                            i++
                            let { ResDate, Slots, MaxReservation } = el
                            let n = new Date(ResDate)
                            let d = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()

                            let obj = {
                              value: MaxReservation,
                              label: MaxReservation,
                            }

                            return (
                              <tr key={i}>
                                <td>{d}</td>
                                <td>
                                  dag max
                                  <br />
                                  <Select
                                    key={ResDate}
                                    value={obj}
                                    onChange={(e) => {
                                      console.log(this)
                                      let calander = this.state.calander
                                      for (let val = 0; val < calander.length; val++) {
                                        if (calander[val].ResDate === ResDate) {
                                          calander[val].MaxReservation = e.value
                                          for (let val2 = 0; val2 < calander[val].Slots.length; val2++) {
                                            calander[val].Slots[val2].SlotReservations = 0
                                            calander[val].Slots[val2].ResLeft = e.value
                                          }
                                        }
                                      }

                                      this.setState({ calander: calander })
                                    }}
                                    defaultValue={TableRes[0]}
                                    options={TableRes}
                                  />
                                </td>
                                {Slots.map((el) => {
                                  let { SlotOpeningTime, SlotReservations, ResLeft } = el
                                  let minutes = parseInt((SlotOpeningTime / (1000 * 60)) % 60),
                                    hours = parseInt((SlotOpeningTime / (1000 * 60 * 60)) % 24)

                                  hours = hours < 10 ? '0' + hours : hours
                                  minutes = minutes < 10 ? '0' + minutes : minutes
                                  let t = []
                                  for (let e = 0; e <= ResLeft; e++) {
                                    let obj = {
                                      value: e,
                                      label: e,
                                    }
                                    if (e === 0) {
                                      obj = {
                                        value: 0,
                                        label: '0',
                                      }
                                    } else
                                      obj = {
                                        value: e,
                                        label: e,
                                      }
                                    t.push(obj)
                                  }
                                  let obj2 = {
                                    value: SlotReservations,
                                    label: SlotReservations,
                                  }
                                  return (
                                    <td key={SlotOpeningTime}>
                                      {hours + ':' + minutes}
                                      <br />
                                      {
                                        <Select
                                          key={SlotOpeningTime}
                                          value={obj2}
                                          onChange={(e) => {
                                            let calander = this.state.calander
                                            for (let val = 0; val < calander.length; val++) {
                                              if (calander[val].ResDate === ResDate) {
                                                for (let val2 = 0; val2 < calander[val].Slots.length; val2++) {
                                                  if (calander[val].Slots[val2].SlotOpeningTime === SlotOpeningTime) {
                                                    let minutes = parseInt(
                                                      (calander[val].Slots[val2].SlotOpeningTime / (1000 * 60)) % 60,
                                                    ),
                                                      hours = parseInt(
                                                        (calander[val].Slots[val2].SlotOpeningTime / (1000 * 60 * 60)) %
                                                        24,
                                                      )

                                                    hours = hours < 10 ? '0' + hours : hours
                                                    minutes = minutes < 10 ? '0' + minutes : minutes
                                                    console.log(hours + ':' + minutes)

                                                    calander[val].Slots[val2].SlotReservations = e.value
                                                    console.log(calander[val].Slots[val2].SlotReservations)
                                                    this.setState({ calander: calander })
                                                  }
                                                }

                                                let n = new Date(ResDate)
                                                let d = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()

                                                console.log('Iam in the ' + d)
                                              }
                                            }
                                            /* console.log(calander)
                this.setState({calander:calander})*/
                                          }}
                                          options={t}
                                        />
                                      }
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </Form.Row>
                </div>
                <Form.Row>
                  <Form.Group as={Col}></Form.Group>
                  <Form.Group as={Col}></Form.Group>
                  <Form.Group as={Col}></Form.Group>
                  <Form.Group as={Col}>
                    <Button variant="primary" type="submit">
                      OPSLAAN
                    </Button>
                    <Link to="/Home/Update">
                      <Button variant="danger">ANNULEER</Button>
                    </Link>
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Calender

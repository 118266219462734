import React from 'react'
import Success from '../../../Alert/Success'
import { Link, Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import auth from '../../../Auth/auth'
import LoadingBar from 'react-top-loading-bar'
import axios from 'axios'
class Pay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Charge: 0,
      SubTotal: 0,
      Vat21: 0,
      total: 0,
      Accept: false,
      status: false,
      Desc: 'Borgovereenkomst',
      Success: false,
      Success2: false,
      msg: '',
      loadingBarProgress: 0,
    }
  }

  componentDidMount() {
    let path = window.location.pathname
    if (path === '/Home/Order') {
      let { user_name, Usergroup } = JSON.parse(localStorage.getItem('user'))

      let obj = {
        usergroup: Usergroup,
        username: user_name,
      }
      axios
        .post('https://provider.beelicensed.com/providers/calculateSubscription', obj)
        .then((result) => {
          if (result.data.status === 200) {
            console.log(result.data)
            let com=parseFloat(result.data.commision)
            this.setState({
              Charge: com,
              Desc: result.data.desc,
              SubTotal: com,
              Vat21: com * result.data.vat,
              total: (com + com * result.data.vat).toFixed(2),
            })
          } else console.log('No data')
        })
        .catch((err) => {
          console.log('Error')
        })
    }

    if (path === '/Home/PayComission') {
      let { user_name } = JSON.parse(localStorage.getItem('user'))

      let obj = {
        username: user_name,
      }
      axios
        .post('https://provider.beelicensed.com/providers/calculateResCost', obj)
        .then((result) => {
          if (result.data.status === 200) {
            let com=parseFloat(result.data.commision);
            let vat = (result.data.vat/100);
            this.setState({
              Charge: com,
              Desc: 'Total Price',
              SubTotal: com,
              Vat21: com * vat,
              total: (com + com * vat).toFixed(2),
            })

            console.log(com)
          } else console.log('No data')
        })
        .catch((err) => {
          console.log('Error')
        })
    }
  }
  SubmitForm = (event, history) => {
  
    event.preventDefault()

    this.add(99);
    if(!(this.state.total>0)){
      this.setState({ loadingBarProgress: 100, status: true, Success: false, msg: 'No fee left to pay' })
    }
    else{
    let { user_name, Usergroup } = JSON.parse(localStorage.getItem('user'))

    let obj = {
      username: user_name,
      vat: this.state.Vat21,
      total: this.state.total,
      Desc: this.state.Desc,
      SubTotal: this.state.SubTotal,
    }
    console.log(obj)
    let path = window.location.pathname
    if (path === '/Home/Order') {
      axios
        .post('https://provider.beelicensed.com/providers/GetPaymentUrl', obj)
        .then((result) => {
          if (result.data.status === 200) {
            this.complete()
            window.location.assign(result.data.url)
          } else this.setState({ loadingBarProgress: 100, status: true, Success: false, msg: result.data.err })
        })
        .catch((err) => {
          console.log('Error')
          this.setState({ loadingBarProgress: 100, status: true, Success: false, msg: 'Seems like network error' })
        })
    } else if (path === '/Home/PayComission') {
      axios
        .post('https://provider.beelicensed.com/providers/GetCommissionUrl', obj)
        .then((result) => {
          if (result.data.status === 200) {
            this.complete()
            window.location.assign(result.data.url)
          } else this.setState({ loadingBarProgress: 100, status: true, Success: false, msg: result.data.err })
        })
        .catch((err) => {
          console.log('Error')
          this.setState({ loadingBarProgress: 100, status: true, Success: false, msg: 'Seems like network error' })
        })
    }
  }

  }
  complete = () => {
    this.setState({ loadingBarProgress: 100 })
  }
  onLoaderFinished = () => {
    this.setState({ loadingBarProgress: 0 })
  }
  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, Success2: true })
    else this.setState({ status: false, Success2: false })
  }
  add = (value) => {
    this.setState({
      loadingBarProgress: this.state.loadingBarProgress + value,
    })
  }
  render() {
    let { history } = this.props
    let path = window.location.pathname

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
        <LoadingBar
          progress={this.state.loadingBarProgress}
          height={4}
          color="black"
          onLoaderFinished={() => this.onLoaderFinished()}
        />
        {this.state.Success2 ? (
          <Redirect
            to={{
              pathname: '/Home/genral',
              state: { User: this.state },
            }}
          />
        ) : (
          <div className="editContainer" style={{ maxWidth: '500px', margin: '0 0 0 10%' }}>
            <div className="content">
              <div className="row">
                <Form className="Form" onSubmit={(e) => this.SubmitForm(e, history)}>
                  <div className="card-header ">
                    <h4 className="card-title"></h4>
                  </div>

                  <div className="card-body">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label style={{ color: 'black', fontWeight: 'bolder', fontSize: '1.2rem' }}>
                          {this.state.Desc}
                        </Form.Label>
                        {path === '/Home/Order' ? (
                          <Form.Control
                            required
                            type="number"
                            step="0.1"
                            value={this.state.Charge}
                            readOnly
                            style={{ cursor: 'not-allowed' }}
                          />
                        ) : null}
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>Sub Totaal</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          value={this.state.SubTotal}
                          readOnly
                          style={{ cursor: 'not-allowed' }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridNew">
                        <Form.Label>BTW bij 21%</Form.Label>

                        <Form.Control
                          type="number"
                          value={this.state.Vat21}
                          readOnly
                          style={{ cursor: 'not-allowed' }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridNew">
                        <Form.Label>Totaal </Form.Label>

                        <Form.Control
                          type="number"
                          value={this.state.total}
                          readOnly
                          style={{ cursor: 'not-allowed' }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        required
                        label="   ja, ik ga akkoord met
                              voorwaarden
                              en
                              privacybeleid
                              "
                        onChange={(e) => {
                          this.setState({ Accept: !this.state.billing })
                        }}
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      GA NAAR BETALEN
                    </Button>
                    <Link to="/Home">
                      <Button variant="danger">ANNULEER</Button>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Pay

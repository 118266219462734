import React from 'react'
import Success from '../../../Alert/Success'
import { Link, Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import auth from '../../../Auth/auth'
import axios from 'axios'

class Pass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      Old: '',
      New: '',
      Confirm: '',
      status: false,
      Success: false,
      Success2: false,
      msg: '',
    }
  }

  componentDidMount() {
    let { user_name } = JSON.parse(localStorage.getItem('user'))
    this.setState({ username: user_name })
  }

  SubmitForm = (event) => {
    event.preventDefault()
    let { password } = JSON.parse(localStorage.getItem('user'))
    console.log(password + ' ' + this.state.Old)

    if (this.state.Confirm === this.state.New) {
      axios
        .post('https://provider.beelicensed.com/providers/ChangePass', this.state)
        .then((result) => {
          if (result.data.status === 200) {
            this.setState({ status: true, Success: true, msg: 'Password Changed successfully' })
          } else this.setState({ status: true, Success: false, msg: 'Somthing went wrong' })
        })
        .catch((err) => {
          this.setState({ status: true, Success: false, msg: err.data.msg })
        })
    } else this.setState({ status: true, Success: false, msg: 'New Password donot match' })
  }

  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, Success2: true })
    else this.setState({ status: false, Success2: false })
  }

  render() {
    return (
      <div>
        {this.state.Success2 ? (
          <Redirect
            to={{
              pathname: '/Home',
              state: { User: this.state },
            }}
          />
        ) : (
          <div className="editContainer">
            <div className="content">
              <div className="row">
                <Form className="Form" onSubmit={this.SubmitForm}>
                  <div className="card-header ">
                    <h4 className="card-title">verander wachtwoord</h4>
                  </div>

                  <div className="card-body">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>huidig wachtwoord *</Form.Label>
                        <Form.Control
                          required
                          type="password"
                          placeholder="huidig wachtwoord *"
                          value={this.state.Old}
                          onChange={(e) => {
                            this.setState({ Old: e.target.value })
                          }}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridNew">
                        <Form.Label>nieuw wachtwoord *</Form.Label>

                        <Form.Control
                          required
                          type="password"
                          placeholder="nieuw wachtwoord "
                          value={this.state.New}
                          onChange={(e) => {
                            this.setState({ New: e.target.value })
                          }}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridConfirm">
                        <Form.Label>bevestig wachtwoord *</Form.Label>
                        <Form.Control
                          required
                          type="password"
                          placeholder="bevestig wachtwoord  "
                          value={this.state.Confirm}
                          onChange={(e) => {
                            this.setState({ Confirm: e.target.value })
                          }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Button variant="primary" type="submit">
                      verander wachtwoord
                    </Button>
                    <Link to="/Home">
                      <Button variant="danger">ANNULEER</Button>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Pass

import React from 'react'
import ReactDOM from 'react-dom'
import { MdDashboard, MdMap } from 'react-icons/md'
import { FiSettings, FiGrid, FiCalendar, FiShoppingBag } from 'react-icons/fi'
import { FaFileInvoiceDollar, FaUserCircle } from 'react-icons/fa'
import { AiOutlineBank } from 'react-icons/ai'
import Success from '../../../Alert/Success'
import auth from '../../../Auth/auth'

import axios from 'axios'
import { Link, Redirect, NavLink } from 'react-router-dom'

import './Styling.css'
//import userlogo from './../../../../include/assets/images/avatars/user.png'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      Activation: '',
      group: '',
      log: '',
      subscription: '',
      path: '',
      alert: false,
      Redirect: false,
      msg: '',
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    let obj = JSON.parse(localStorage.getItem('user'))
    console.log(auth.UserGroupTitle.Provider)

    if (JSON.parse(localStorage.getItem('user'))) {
      axios
        .post('https://provider.beelicensed.com/providers/UserInfo', obj)
        // .post('http://localhost:53000/providers/UserInfo', obj)
        .then((res) => {
          if (res.data.status === 200) {
            localStorage.setItem('user', JSON.stringify(res.data.user))
            localStorage.setItem('selected-cat', JSON.stringify(res.data.cat))

            let usergroup = res.data.usergroup
            if (!(usergroup.title === auth.UserGroupTitle.Poppingtime))
              localStorage.setItem('region', JSON.stringify(res.data.region))

            localStorage.setItem('user-group', JSON.stringify(usergroup))
            if (JSON.parse(localStorage.getItem('user'))) {
              let { user_name, LastLogin, subscription, Active } = JSON.parse(localStorage.getItem('user'))
              // console.log(JSON.parse(localStorage.getItem('user-group')))
              let { title } = usergroup

              let n = new Date(LastLogin)
              let n2 = new Date(Active)
              let LLogin = months[n.getMonth()] + ' ' + n.getDate() + ',' + n.getFullYear()
              let Since = months[n2.getMonth()] + ' ' + n2.getDate() + ',' + n2.getFullYear()
              if (!LastLogin) LLogin = ''
              this.setState({
                username: user_name,
                group: title,
                log: LLogin,
                subscription: subscription,
                Activation: Since,
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    //
  }

  Close = (e) => {
    var body = document.querySelector('body').classList
    var clas = document.querySelector('.nav-toggle').classList
    var clas2 = document.querySelector('.toggleable').classList
    var clas3 = document.querySelector('.me2').classList
    var clas4 = document.querySelector('.me3').classList
    var classes = document.querySelector('.SubMenu2').classList
    var class2 = document.querySelector('.lastC').classList

    class2.remove('Rotate')
    classes.remove('show')
    clas4.remove('opened4')
    clas3.remove('opened3')
    clas2.remove('opened2')
    clas.remove('opened')

    body.remove('unablescroll')
  }

  CloseME = (e) => {
    var clas4 = document.querySelector('.me3').classList
    var classes = document.querySelector('.SubMenu2').classList
    var class2 = document.querySelector('.lastC').classList
    var clas3 = document.querySelector('.me2').classList
    clas4.remove('opened4')
    class2.remove('Rotate')
    classes.remove('show')
    clas3.remove('opened3')
  }
  HideStatus = (e) => {
    if (this.state.alert) {
      this.Close(4)
      this.CloseME(3)
      this.setState({ alert: false, Redirect: true })
    } else this.setState({ status: false, Success2: false })
  }
  render() {
    let bg = 'gooli'
    if (this.state.bgGroup === 1) bg = 'Cart'
    if (this.state.bgGroup === 4) bg = 'Learn'
    if (this.state.bgGroup === 2) bg = 'Charity'
    return (
      <div>
        {this.state.Redirect ? (
          <Redirect
            to={{
              pathname: this.state.path,
              state: { User: this.state },
            }}
          />
        ) : null}
        <nav className="navbar navbar-expand-lg fixed-top nav-down">
          <div className="container">
            <div className="navbar-translate">
              <Link to="/Home/BewerkProfiel">
                {/*<img className="img-circle WelcomeText" src={userlogo} />*/}
                <FaUserCircle className="user-circle" />
              </Link>

              <Link to="/Home" className="navbar-brand ">
                WELKOM TERUG{' '}
              </Link>
            </div>
            {/*
                    <BrowserView>
                    <div className="NavLinks" >
                      
                        <div className="NavItem">
                        Profiel  
                        </div>

                        <div className="NavItem">
                        Producten   
                        </div>

                        <div className="NavItem2"> 
                        afmelden
                        </div>
                        
                    </div>
                    </BrowserView>
                    
                    */}

            <div className="toggleable">
              <div className="nav-overlay" onClick={this.Close}></div>
              <div
                className="nav-toggle"
                onClick={(e) => {
                  this.CloseME(e)
                  var body = document.querySelector('body').classList
                  var clas = document.querySelector('.nav-toggle').classList
                  var clas2 = document.querySelector('.toggleable').classList
                  var clas3 = document.querySelector('.me2').classList
                  var clas4 = document.querySelector('.me3').classList
                  var classes = document.querySelector('.SubMenu2').classList
                  var class2 = document.querySelector('.lastC').classList

                  if (!clas.contains('opened')) {
                    clas.add('opened')
                    clas2.add('opened2')
                    body.add('unablescroll')
                  } else {
                    this.Close(e)
                  }
                }}
              >
                <div className="a">
                  <span></span>
                </div>
              </div>
              <div className="nav-menu">
                <div className="menu-wrap">
                  <ul className="menu">
                    <li
                      className="me2"
                      onClick={(e) => {
                        var clas = document.querySelector('.me2').classList
                        var clas2 = document.querySelector('.me3').classList
                        if (!clas.contains('opened3')) {
                          clas.add('opened3')
                          clas2.remove('opened4')
                        }
                      }}
                    >
                      <div className="NavItemBar ">Profiel</div>
                      <div className="SubMenu">
                        <div className="menu-wrap">
                          <ul className="menu">
                            <NavLink to="/Home/BewerkProfiel">
                              <li onClick={this.Close}>
                                <div className="NavItemBar-sub">
                                  <MdDashboard className="Icon" />
                                  Bewerk profiel
                                </div>
                              </li>
                            </NavLink>
                            <NavLink to="/Home/Wachtwoord">
                              <li onClick={this.Close}>
                                <div className="NavItemBar-sub">
                                  <FiSettings className="Icon" />
                                  Wachtwoord
                                </div>
                              </li>
                            </NavLink>

                            <li>
                              <div
                                className="NavItemBar-sub lastC"
                                onClick={(e) => {
                                  var clas = document.querySelector('.SubMenu2').classList
                                  var clas2 = document.querySelector('.lastC').classList
                                  if (!clas.contains('show')) {
                                    clas.add('show')
                                    clas2.add('Rotate')
                                  } else {
                                    clas.remove('show')
                                    clas2.remove('Rotate')
                                  }
                                }}
                              >
                                Instellingen
                              </div>

                              <div className="SubMenu2">
                                <NavLink to="/Home/genral">
                                  <div className="SubMenu2Items" onClick={this.Close}>
                                    <FiSettings className="Icon" />
                                    Algemeen
                                  </div>
                                </NavLink>
                                {
                                !(this.state.group === auth.UserGroupTitle.Loyalswan) ? (
                                <NavLink to="/Home/Bankinformatie">
                                  <div className="SubMenu2Items" onClick={this.Close}>
                                    <AiOutlineBank className="Icon" />
                                    Bankinformatie
                                  </div>
                                </NavLink>):
                                null
                                }

                                {
                                !(this.state.group === auth.UserGroupTitle.Poppingtime) ? (
                                  <NavLink to="/Home/Regio">
                                    <div className="SubMenu2Items" onClick={this.Close}>
                                      <MdMap className="Icon" />
                                      Regio
                                    </div>
                                  </NavLink>
                                ) : null
                                }
                                <NavLink to="/Home/Categorieën">
                                  <div className="SubMenu2Items" onClick={this.Close}>
                                    <FiGrid className="Icon" />
                                    Categorieën
                                  </div>
                                </NavLink>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li
                      className="me3"
                      onClick={(e) => {
                        // console.log(this.state.subscription)
                        let cat = JSON.parse(localStorage.getItem('selected-cat'))
                        let region
                        if (!(this.state.group === auth.UserGroupTitle.Poppingtime))
                          region = JSON.parse(localStorage.getItem('region'))

                        if (!this.state.subscription || !this.state.subscription.CurrentStatus) {
                          this.setState({ alert: true, msg: 'Buy subscription first', path: '/Home/genral' })
                        } else if (region === null && !(this.state.group === auth.UserGroupTitle.Poppingtime)) {
                          this.setState({ alert: true, msg: 'Please select the region first', path: '/Home/Regio' })
                        } else if (cat.length < 2 &&!(this.state.group === auth.UserGroupTitle.Loyalswan)) {
                          this.setState({
                            alert: true,
                            msg: 'Please select the categoies first',
                            path: '/Home/Categorieën',
                          })
                        } else {
                          var clas = document.querySelector('.me2').classList
                          var clas2 = document.querySelector('.me3').classList
                          var classes = document.querySelector('.SubMenu2').classList
                          var class2 = document.querySelector('.lastC').classList
                          if (!clas2.contains('opened4')) {
                            clas2.add('opened4')
                            clas.remove('opened3')
                            classes.remove('show')
                            class2.remove('Rotate')
                          }
                        }
                      }}
                    >
                      <div className="NavItemBar higlight">Producten</div>

                      <div className="SubMenu">
                        <div className="menu-wrap">
                          <ul className="menu">
                            <NavLink to="/Home/Products">
                              <li>
                                <div className="NavItemBar-sub" onClick={this.Close}>
                                  <FiShoppingBag className="Icon" />
                                  Producten
                                </div>
                              </li>
                            </NavLink>
                            <NavLink to="/Home/Invoices">
                              <li>
                                <div className="NavItemBar-sub " onClick={this.Close}>
                                  <FaFileInvoiceDollar className="Icon" />
                                  Facturen
                                </div>
                              </li>
                            </NavLink>
                            <NavLink to="/Home/Res">
                              <li>
                                <div className="NavItemBar-sub" onClick={this.Close}>
                                  <FiCalendar className="Icon" />
                                  Reserveringen
                                </div>
                              </li>
                            </NavLink>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <NavLink to="/">
                      <li className="me2">
                        <div className="NavItemBar2 highlight" onClick={this.Close}>
                          afmelden
                        </div>
                      </li>
                    </NavLink>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className={`middle ${bg}`}>
          <div className="UserBox">
            <div className="UserHeader Typo">
              <div className="UserTitle">{this.state.username}</div>
            </div>
            <div className="UserBody">
              <div className="UserSemi">{`Gebruikersgroep: ${this.state.group}`}</div>

              <div className="UserRegular">
                Actief sinds {this.state.Activation}.
                <br />
                Laatst ingelogd op {this.state.log ? this.state.log : ''}.
                <br />
                Bel 06 48147864 voor ondersteuning
              </div>
            </div>
          </div>
        </div>

        {this.state.alert ? <Success msg={this.state.msg} status={false} OnClose={this.HideStatus} /> : null}
      </div>
    )
  }
}

export default Nav

class Auth {
  constructor() {
    this.authenticated = false
    this.User = null
    this.UserGroupTitle = {
      Provider: 'Provider',
      Poppingtime: 'Poppingtime',
      WalkandDrinks: 'WalkandDrinks',
      Slothsy: 'Slothsy',
      Loyalswan:'Loyalswan'
    }
    this.Usergroups = [
      {
        Usergroup: 'Provider',
      AddForm:'/Home/NewProduct',
      UpdateForm:'/Home/Update'

    },
     { Usergroup: 'Poppingtime',
      AddForm:'/Home/NewProduct',
      UpdateForm:'/Home/Update'
    },
      {
        Usergroup: 'WalkandDrinks',
    AddForm:'/Home/NewProduct',
    UpdateForm:'/Home/Update'
    },
      {Usergroup: 'Slothsy',
    AddForm:'/Home/NewProduct',
    UpdateForm:'/Home/NewProduct'
    },
    {
      Usergroup:'Loyalswan',
    AddForm:'/Home/NewProductLS',
    UpdateForm:'/Home/EditProductLS'
  }
    ]
  }

  login(cb) {
    this.authenticated = true

    this.User = JSON.parse(localStorage.getItem('user'))
    console.log(this.User)
    setTimeout(cb, 100)
  }
  loadUser() {
    return this.User
    /* axios.post('http://localhost:5000/providers/UserInfo',this.User).then(result=>{
            if(result.data.status===200)
            return result.data.user;
            else
            return null;
        }).catch(err=>{
            return null
        });*/
  }
  getAuthentication() {
    //console.log(localStorage.getItem('user'))
    if (localStorage.getItem('user')) return true
    else return false
  }
  getUser() {
    return this.User
  }
  Signout(cb) {
    this.authenticated = false
    this.User = null
    localStorage.clear()
    setTimeout(cb, 100)
  }
  ProductFormRedirectRoute(usergroup){
    
  
  let obj = this.Usergroups.find(o => o.Usergroup ===  usergroup);
  
  console.log(obj);
  return obj;

  }
}
export default new Auth()

import React from 'react'
import Success from '../../../Alert/Success'
import { Link, Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import auth from '../../../Auth/auth'
import axios from 'axios'

class Bank extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      IBAN: '',
      BIC: '',
      Asc: '',
      Desc: '',
      status: false,
      Success: false,
      Success2: false,
      msg: '',
    }
  }

  componentDidMount() {
    let { user_name, IBAN, BIC, Asc, Desc } = JSON.parse(localStorage.getItem('user'))

    this.setState({
      username: user_name,
      IBAN: IBAN,
      BIC: BIC,
      Asc: Asc,
      Desc: Desc,
    })
  }

  SubmitForm = (event) => {
    event.preventDefault()

    axios
      .post('https://provider.beelicensed.com/providers/Bank', this.state)
      .then((result) => {
        if (result.data.status === 200) {
          localStorage.clear()
          localStorage.setItem('user', JSON.stringify(result.data.user))
          this.setState({ status: true, Success: true, msg: '' })
        } else this.setState({ status: true, Success: false, msg: result.data.msg })
      })
      .catch((err) => {
        this.setState({ status: true, Success: false, msg: err.data.msg })
      })
  }

  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, Success2: true })
    else this.setState({ status: false, Success2: false })
  }

  render() {
    return (
      <div>
        {this.state.Success2 ? (
          <Redirect
            to={{
              pathname: '/Home',
              state: { User: this.state },
            }}
          />
        ) : (
          <div className="editContainer">
            <div className="content">
              <div className="row">
                <Form className="Form" onSubmit={this.SubmitForm}>
                  <div className="card-header ">
                    <h4 className="card-title">Bankinformatie</h4>
                  </div>

                  <div className="card-body">
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>IBAN *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="IBAN "
                          value={this.state.IBAN}
                          onChange={(e) => {
                            this.setState({ IBAN: e.target.value })
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>BIC code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="BIC code"
                          value={this.state.BIC}
                          onChange={(e) => {
                            this.setState({ BIC: e.target.value })
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>Tenaamstelling *</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Tenaamstelling "
                          value={this.state.Asc}
                          onChange={(e) => {
                            this.setState({ Asc: e.target.value })
                          }}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridNew">
                        <Form.Label>Omschrijving *</Form.Label>

                        <Form.Control
                          as="textarea"
                          placeholder="Omschrijving  "
                          value={this.state.Desc}
                          onChange={(e) => {
                            this.setState({ Desc: e.target.value })
                          }}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Button variant="primary" type="submit">
                      BIJWERKEN
                    </Button>
                    <Link to="/Home">
                      <Button variant="danger">ANNULEER</Button>
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Bank

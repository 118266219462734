import React from 'react'
import Success from '../../../Alert/Success'
import { Link, Redirect } from 'react-router-dom'
import LoadingBar from 'react-top-loading-bar'
import ImageUploader from 'react-images-upload'
import DatePicker from 'react-date-picker'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import './Update.css'
import auth from '../../../Auth/auth'
import axios from 'axios'
import { MdTitle } from 'react-icons/md'

const urls = ''
class Update extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      id: -1,
      categories: [],
      subcategories: [],
      Name: '',
      Start: '',
      End: '',
      StartDate: '',
      EndDate: '',
      ImgTemp: '',
      Img: '',
      PermImg: '',
      ImgFrame: '',
      Category: '',
      Price: '',
      Drink_Name: '',
      Bites: '',
      Activation: false,
      ProductDesc: '',
      SubCat: '',
      redirect: false,
      promotion: false,
      Stock: false,
      status: false,
      Success: false,
      Success2: false,
      msg: '',
      loadingBarProgress: 0,
      StockDirect: false,
      cat_title: '',
      region: '',
    }
  }

  componentDidMount() {
    console.log(auth.UserGroupTitle)
    let user = JSON.parse(localStorage.getItem('user'))
    if(!user)
    user=JSON.parse(localStorage.getItem('username'))
    console.log(JSON.parse(localStorage.getItem('user')))
    let path = window.location.pathname
    let usergroup = JSON.parse(localStorage.getItem('user-group')).title
    console.log(usergroup)
    if (usergroup === 'Poppingtime') {
      console.log('Working')
    }

    if (localStorage.getItem('StockImg') && localStorage.getItem('GetStock')) {
      let info2 = JSON.parse(localStorage.getItem('GetStock'))
      let ImgT = localStorage.getItem('StockImg')
      localStorage.removeItem('StockImg')
      localStorage.removeItem('GetStock')
      let bites = ''
      let drink = ''
      console.log(
        info2,
      ) 
      let {
        username,
        Name,
        Start,
        End,
        StartDate,
        EndDate,
        ImgTemp,
        PermImg,
        ImgFrame,
        Category,
        Price,
        Activation,
        promotion,
        ProductDesc,
        region,
        SubCat,
        cat_title,
        Stock,
        subcategories,
        id,
      } = info2

      if (usergroup === auth.UserGroupTitle.Provider)
        this.setState({
          username: username,
          region: region,
          promotion: promotion,
          Name: Name,
          id: id,
          Category: Category,
          SubCat: SubCat,
          subcategories: subcategories,
          cat_title: cat_title,
          Price: Price,
          Activation: Activation,
          ProductDesc: ProductDesc,
          PermImg: PermImg,
          Img: ImgT,
          ImgTemp: ImgT,
          StartDate: StartDate,
          Stock: true,
          EndDate: EndDate, categories:info2.categories,
          Start: new Date(StartDate),
          End: new Date(EndDate),
        })
      else if (usergroup === auth.UserGroupTitle.Poppingtime)
        this.setState({
          username: username,
          promotion: promotion,
          Name: Name,
          id: id,
          Category: Category,
          categories:info2.categories,
          SubCat: SubCat,
          subcategories: subcategories,
          cat_title: cat_title,
          Price: Price,
          Bites: info2.Bites,
          Drink_Name: info2.Drink_Name,
          Activation: Activation,
          Stock: true,
          ProductDesc: ProductDesc,
          ImgTemp: ImgT,
          Img: ImgT,
          PermImg: PermImg,
          StartDate: StartDate,
          EndDate: EndDate,
          Start: new Date(StartDate),
          End: new Date(EndDate),
        })
      else
        this.setState({
          username: username,
          region: region,
          promotion: promotion,
          id: id,
          Name: Name,
          Category: Category,
          categories:info2.categories,
          SubCat: SubCat,
          subcategories: subcategories,
          cat_title: cat_title,
          Price: Price,
          Activation: Activation,
          ProductDesc: ProductDesc,
          PermImg: PermImg,
          Img: ImgT,
          ImgTemp: ImgT,
          StartDate: StartDate,
          Stock: true,
          EndDate: EndDate,
          Start: new Date(StartDate),
          End: new Date(EndDate),
        })
    } else if (path === '/Home/Update' && localStorage.getItem('EditProductId')) {
      let m = JSON.parse(localStorage.getItem('EditProductId'))

      let { user_name } = user
      let object = {
        username: user_name,
        id: m.id,
      }
      let bites = ''
      let drink = ''

      axios
        .post('https://provider.beelicensed.com/providers/getSubCatByCat', object)
        .then((res) => {
          console.log(res.data.status)
          if (res.data.status === 200) {
            let info = res.data.Product
            let {
              _id,
              Name,
              Category,
              SubCat,
              Price,
              region,
              Activation,
              ProductDesc,
              Img,
              StartDate,
              promotion,
              EndDate,
              calander,
            } = info

            if (usergroup === auth.UserGroupTitle.Poppingtime) {
              bites = info.Bites
              drink = info.Drink_Name
              console.log('this also working')
            }
            if (usergroup === auth.UserGroupTitle.Provider)
              this.setState({
                username: user_name,
                region: region,
                id: _id,
                promotion: promotion,
                Name: Name,
                Category: Category,
                SubCat: SubCat,
                subcategories: res.data.data,
                cat_title: res.data.title,
                Price: Price,
                Activation: Activation,
                ProductDesc: ProductDesc,
                PermImg: Img.Img,
                StartDate: StartDate,
                EndDate: EndDate,
                Start: new Date(StartDate),
                End: new Date(EndDate),
              })
            else if (usergroup === auth.UserGroupTitle.Poppingtime)
              this.setState({
                username: user_name,
                id: _id,
                promotion: promotion,
                Name: Name,
                Category: Category,
                SubCat: SubCat,
                subcategories: res.data.data,
                cat_title: res.data.title,
                Price: Price,
                Bites: bites,
                Drink_Name: drink,
                Activation: Activation,
                ProductDesc: ProductDesc,
                PermImg: Img.Img,
                StartDate: StartDate,
                EndDate: EndDate,
                Start: new Date(StartDate),
                End: new Date(EndDate),
              })
            else
              this.setState({
                username: user_name,
                region: region,
                id: _id,
                promotion: promotion,
                Name: Name,
                Category: Category,
                SubCat: SubCat,
                subcategories: res.data.data,
                cat_title: res.data.title,
                Price: Price,
                Activation: Activation,
                ProductDesc: ProductDesc,
                PermImg: Img.Img,
                StartDate: StartDate,
                EndDate: EndDate,
                Start: new Date(StartDate),
                End: new Date(EndDate),
              })
          } else this.setState({ status: true, Success: false, msg: res.data.msg })
        })
        .catch((err) => {
          console.log(err)
          this.setState({ status: true, Success: false, msg: 'Network error' })
        })

      // axios.post("/providers/CatInfo",this.state)
    } else {
      //const regio=JSON.parse(localStorage.getItem('region'))._id
      const categories = JSON.parse(localStorage.getItem('selected-cat'))
      let { user_name } = user

      if (usergroup === auth.UserGroupTitle.Poppingtime) this.setState({ username: user_name, categories: categories })
      else {
        let { _id } = JSON.parse(localStorage.getItem('region'))
        console.log(JSON.parse(localStorage.getItem('region')))
        this.setState({ username: user_name, region: _id, categories: categories })
      }
    }
  }

  SubmitForm = (event) => {
    event.preventDefault()
    let state = this.state
    //AddProductOnly
    this.add(99)
    console.log(this.state)
    if (this.Validate()) {
      let path = window.location.pathname
      if (path === '/Home/Update') {
        let Form = new FormData()
        let paths = ''
        if (state.Img && !state.Stock) {
          Form.append('file', state.Img)
          Form.append('data', JSON.stringify(state))
          paths = 'https://provider.beelicensed.com/providers/UpdateProductAndImg'
        } else {
          paths = 'https://provider.beelicensed.com/providers/UpdateProduct'
          Form = state
        }
        console.log(this.state)
        axios
          .post(paths, Form)
          .then((result) => {
            this.complete()
            if (result.data.status === 200) {
              this.setState({ status: true, Success: true, msg: 'form Submitted Successfully' })
            } else this.setState({ status: true, Success: false, msg: result.data.msg })
          })
          .catch((err) => {
            this.complete()
            this.setState({ status: true, Success: false, msg: 'Went wrong' })
          })
      } else {
        console.log(this.state)

        let Form = new FormData()
        let paths = ''
        if (state.Img && !state.Stock) {
          Form.append('file', state.Img)
          Form.append('data', JSON.stringify(state))
          paths = 'https://provider.beelicensed.com/providers/AddProduct'
        } else {
          paths = 'https://provider.beelicensed.com/providers/AddProductOnly'
          Form = state
        }
        axios
          .post(paths, Form)
          .then((result) => {
            this.complete()
            if (result.data.status === 200) {
              this.setState({ status: true, Success: true, msg: 'Form Submitted Successfully' })
            } else this.setState({ status: true, Success: false, msg: result.data.msg })
          })
          .catch((err) => {
            this.complete()
            this.setState({ status: true, Success: false, msg: 'network error' })
          })
      }
    } else this.setState({ status: true, Success: false, msg: 'fields are empty cannot proceed' })
  }

  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, Success2: true })
    else this.setState({ status: false, Success2: false })
  }

  Fun = (e, value) => {
    this.setState({ selected: value })
  }
  AddCalender = (e) => {
    this.add(99)
    let state = this.state
console.log(this.state)
    if (this.Validate()) {
      let Form = new FormData()
      let paths = ''
      if (state.Img && !state.Stock) {
        Form.append('file', state.Img)
        Form.append('data', JSON.stringify(state))
        paths = 'https://provider.beelicensed.com/providers/UpdateProductAndImg'
      } else {
        paths = 'https://provider.beelicensed.com/providers/UpdateProduct'
        Form = state
      }

      axios
        .post(paths, Form)
        .then((result) => {
          this.complete()
          if (result.data.status === 200) {
            localStorage.setItem('Product', JSON.stringify(this.state))
            localStorage.setItem('user', JSON.stringify(this.state))
            this.setState({ status: false, redirect: true, Success: true, msg: '' })
          } else this.setState({ status: true, Success: false, msg: result.data.msg })
        })
        .catch((err) => {
          this.complete()
          this.setState({ status: true, Success: false, msg: "some error" })
        })
    } else this.setState({ status: true, Success: false, msg: 'fields are empty cannot proceed' })

    this.complete()
  }
  Validate = () => {
    console.log(this.state)
    if (
      this.state.StartDate &&
      this.state.EndDate &&
      this.state.Name &&
      this.state.Category &&
      this.state.SubCat &&
      this.state.Price > -1
    )
      return true
    else return false
  }

  add = (value) => {
    this.setState({
      loadingBarProgress: this.state.loadingBarProgress + value,
    })
  }
  complete = () => {
    this.setState({ loadingBarProgress: 100 })
  }
  onLoaderFinished = () => {
    this.setState({ loadingBarProgress: 0 })
  }

  StockPics = () => {
    if (this.Validate()) {
      this.setState()
      let path = window.location.pathname
      if (path === '/Home/Update') localStorage.setItem('request', true)
      else localStorage.setItem('request', false)
      localStorage.setItem('GetStock', JSON.stringify(this.state))
      console.log(this.state)
      this.setState({ StockDirect: true })
    } else this.setState({ status: true, Success: false, msg: 'Please enter data first' })
  }
  changeCat = (e) => {
    var Cat2 = this.state.Category
    Cat2 = e.target.value
    let caba = JSON.parse(localStorage.getItem('selected-cat'))
    let index = 0
    for (let km = 0; km < caba.length; km++) {
      if (caba[km].value === e.target.value) {
        index = km
      }
    }
    let obj = {
      sub_categories: caba[index].sub,
    }
    axios
      .post('https://provider.beelicensed.com/providers/getSubCatByRef', obj)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ Category: Cat2, subcategories: res.data.data })
        } else {
          console.log('some issue in api')
          this.setState({ status: true, Success: false, msg: 'something went wrong' })
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({ status: true, Success: false, msg: 'network error' })
      })
  }

  render() {
    let i,
      u = -1
    let user = JSON.parse(localStorage.getItem('user'))
    let usergroup = JSON.parse(localStorage.getItem('user-group')).title
    console.log(JSON.parse(localStorage.getItem('user-group')).title)
    let path = window.location.pathname

    let Subs = this.state.subcategories
    let categories = this.state.categories

    var newdate = new Date(this.state.Start)

    newdate.setDate(newdate.getDate())

    let MinDate = new Date(newdate)
    console.log(MinDate)
    return (
      <div>
        <LoadingBar
          progress={this.state.loadingBarProgress}
          height={4}
          color="black"
          onLoaderFinished={() => this.onLoaderFinished()}
        />
        {this.state.redirect ? <Redirect to="/Home/Calendar" /> : null}
        {this.state.Success2 ? <Redirect to="/Home/Products" /> : null}

        {this.state.StockDirect ? <Redirect to="/Home/Stock" /> : null}
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <Form className="Form" onSubmit={this.SubmitForm}>
                <div className="card-header ">
                  <h4 className="card-title">Add Product</h4>
                </div>

                <div className="card-body">
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridCurrentPass">
                      <Form.Label>Naam (maximaal 50 tekens) *</Form.Label>
                      <Form.Control
                        required
                        maxlength="50"
                        type="text"
                        placeholder="Naam "
                        value={this.state.Name}
                        onChange={(e) => {
                          console.log(e.target.value)
                          this.setState({ Name: e.target.value })
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group controlId="formGridState">
                      <Form.Label>Categorieën * </Form.Label>

                      {window.location.pathname != '/Home/Update' ? (
                        <Form.Control as="select" onChange={this.changeCat} value={this.state.Category} >
                          <option value="" selected disabled hidden>
                            Kies een categorie
                          </option>

                          {categories.map((el) => {
                            let number = el

                            return (
                              
                              <option key={number} value={number.value}>
                                {number.name}
                              </option>
                            )
                          })}
                        </Form.Control>
                      ) : (
                        <Form.Control as="select" disabled={true}>
                          <option value={this.state.Category}>{this.state.cat_title}</option>
                        </Form.Control>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>

                    {this.state.Category != '' ? (
                      <Form.Group controlId="formGridCurrentPass">
                        <Form.Label>Soort deal *</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          onChange={(e) => {
                            let Cat2 = this.state.SubCat
                            Cat2 = e.target.value
                            this.setState({ SubCat: Cat2 })
                          }}
                        >
                          <option value="none" selected disabled hidden>
                            Kies een deal
                          </option>

                          {Subs.length >= 0
                            ? Subs.map((el) => {
                                let name = el
                                if (name._id === this.state.SubCat) {
                                  return (
                                    <option value={name._id} selected>
                                      {name.name}
                                    </option>
                                  )
                                } else {
                                  return <option value={name._id}>{name.name}</option>
                                }
                              })
                            : null}
                        </Form.Control>
                      </Form.Group>
                    ) : null}
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>Price *</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        value={this.state.Price}
                        success="right"
                        onChange={(e) => {
                          console.log(e.target.value)
                          this.setState({ Price: e.target.value })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  {usergroup === auth.UserGroupTitle.Poppingtime ? (
                    <div>
                      <Form.Row>
                        <Form.Group controlId="formGridEmail">
                          <Form.Label>Bottle/drink name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={this.state.Drink_Name}
                            success="right"
                            onChange={(e) => {
                              console.log(e.target.value)
                              this.setState({ Drink_Name: e.target.value })
                            }}
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group controlId="formGridEmail">
                          <Form.Label>Bites</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={this.state.Bites}
                            success="right"
                            onChange={(e) => {
                              console.log(e.target.value)
                              this.setState({ Bites: e.target.value })
                            }}
                          />
                        </Form.Group>
                      </Form.Row>
                    </div>
                  ) : null}

                  <Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Active "
                        checked={this.state.Activation}
                        value={this.state.Activation}
                        onChange={(e) => {
                          this.setState({ Activation: e.target.checked })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Advertisement"
                        checked={this.state.promotion}
                        value={this.state.promotion}
                        onChange={(e) => {
                          this.setState({ promotion: e.target.checked })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridNew">
                      <Form.Label>Beschrijving *</Form.Label>

                      <Form.Control
                        required
                        as="textarea"
                        placeholder="Voeg hier uw productbeschrijving toe"
                        value={this.state.ProductDesc}
                        onChange={(e) => {
                          this.setState({ ProductDesc: e.target.value })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group controlId="formGridCurrentPass">
                      <Form.Label>Begin datum *</Form.Label>
                      <br />

                      <DatePicker
                        className="calander"
                        minDate={path == '/Home/NewProduct' ? new Date() : ''}
                        required
                        value={this.state.Start}
                        onChange={(date) => {
                          console.log(date.getTime() - Date.now())
                          let dt = date.getTime() - Date.now()
                          if (dt > -8.64e7) this.setState({ Start: date, StartDate: date.getTime() })
                          else this.setState({ status: true, msg: 'Date cannot be less than today', Success: false })
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                    <Form.Group controlId="formGridCurrentPass">
                      <Form.Label>Einddatum *</Form.Label>
                      <br />
                      <DatePicker
                        required
                        className="calander"
                        value={this.state.End}
                        minDate={MinDate}
                        onChange={(date) => {
                          console.log(date)
                          let d = date.getTime()
                          console.log(d)
                          console.log(new Date(d))
                          this.setState({ End: date, EndDate: d })
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>

                    <Form.Group as={Col} controlId="formGridCurrentPass"></Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group id="formGridCheckbox">
                      <Form.Check
                        type="checkbox"
                        label=" Gebruik een stockfoto"
                        checked={this.state.Stock}
                        value={this.state.Stock}
                        onChange={(e) => {
                          this.setState({ Stock: e.target.checked })
                        }}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    {this.state.Stock ? (
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>Afbeelding *</Form.Label>
                        <div className="fileUploader ">
                          <div className="fileContainer">
                            <div className="errorsContainer"></div>
                            {this.state.Img ? (
                              <div className="ImageFrame">
                                <img src={this.state.Img} className="StockImage" />
                              </div>
                            ) : null}
                            <button type="button" onClick={this.StockPics} className="chooseFileButton ">
                              Naar de stockfoto's
                            </button>
                            <div className="uploadPicturesWrapper">
                              <div className="Naar"></div>
                            </div>
                          </div>
                        </div>{' '}
                      </Form.Group>
                    ) : (
                      <Form.Group as={Col} controlId="formGridCurrentPass">
                        <Form.Label>Afbeelding *</Form.Label>

                        <div className="fileUploader ">
                          <div className="fileContainer">
                            {this.state.ImgTemp ? (
                              <div className="ImageFrame">
                                <div
                                  className="deleteImage"
                                  onClick={() => {
                                    let file = document.querySelector('.File')

                                    file.value = ''
                                    this.setState({ ImgTemp: '', Img: '' })
                                  }}
                                >
                                  X
                                </div>
                                <img src={this.state.ImgTemp} className="StockImage" />
                              </div>
                            ) : (
                              <div className="ImageFrame">
                                {path === '/Home/Update' ? (
                                  <img src={`${this.state.PermImg}`} className="StockImage" />
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            <span className="btn btn-outline-default btn-round btn-file">
                              <span className="fileinput-new">Choose Image</span>
                              <input
                                type="file"
                                single="true"
                                accept="image/*"
                                onChange={(e) => {
                                  // console.log(e.target.files[0]);
                                  if (e.target.files[0])
                                    this.setState({
                                      ImgTemp: URL.createObjectURL(e.target.files[0]),
                                      Img: e.target.files[0],
                                    })
                                }}
                                className="File"
                                name="prImage"
                              />
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    )}
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Button variant="primary" onClick={this.SubmitForm}>
                        BIJWERKEN
                      </Button>
                      <Link to="/Home/Products">
                        <Button variant="danger">ANNULEER</Button>
                      </Link>
                    </Form.Group>
                    <Form.Group as={Col}></Form.Group>
                    <Form.Group as={Col}></Form.Group>
                    <Form.Group as={Col}>
                      {path === '/Home/Update' ? (
                        <Button variant="primary" onClick={this.AddCalender}>
                          STOCKSETTINGS
                        </Button>
                      ) : null}
                    </Form.Group>
                  </Form.Row>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Update

import axios from 'axios'
import 'bootstrap-css-only/css/bootstrap.min.css'
import { MDBBtn, MDBInput } from 'mdbreact'
import 'mdbreact/dist/css/mdb.css'
import React from 'react'
import { Link } from 'react-router-dom'
import Success from '../Alert/Success'
import './Login.css'

class forgot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      User: '',
      status: false,
      Success: this.props.succ,
      msg: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.SubmitForm = this.SubmitForm.bind(this)
  }

  handleChange(event) {
    this.setState({ User: event.target.value })
  }
  SubmitForm(event) {
    event.preventDefault()

    axios
      .post('https://provider.beelicensed.com/providers/ForgotPass', this.state)
      .then((result) => {
        if (result.data.status === 200) this.setState({ status: true, Success: true, msg: result.data.msg })
        else this.setState({ status: true, Success: false, msg: result.data.msg })
      })
      .catch((err) => {
        this.setState({ status: true, Success: false, msg: 'Network error' })
      })
  }

  HideStatus = (e) => this.setState({ status: false })

  render() {
    return (
      <div>
        <div className="LoginContainer">
          <div className="LoginInner">
            <div className="coupon_image"></div>

            <form className="LoginForm" onSubmit={this.SubmitForm}>
              <br />
              <br />
              <MDBInput
                name="username"
                label="gebruikersnaam"
                icon="user"
                group
                type="Text"
                validate
                error="wrong"
                onChange={this.handleChange}
                success="right"
                required
              />

              <div className="text-center mb-3">
                <MDBBtn type="submit" gradient="blue" rounded className="btn-block z-depth-1a">
                  Reset wachtwoord
                </MDBBtn>
              </div>
            </form>
            <div className="form-footer footer-signin">
              <div align="center">
                <p className="text-info">
                  <small>
                    <span className="bracket-link">
                      [&nbsp;<Link to="/register">registreren</Link>&nbsp;]
                    </span>{' '}
                    &nbsp;
                    <span className="bracket-link">
                      [&nbsp;<Link to="/"> Inloggen</Link>&nbsp;]
                    </span>
                  </small>
                </p>
                © auteursrecht{' '}
                <Link to="/" target="_blank">
                  couponcard.nl
                </Link>{' '}
                2016, 2020{' '}
              </div>
            </div>
          </div>
        </div>

        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default forgot

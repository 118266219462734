import React from 'react'

import { Link, NavLink, Redirect } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import '../edit.css'
import './Update.css'
import axios from 'axios'

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      request: false,
      Direct: false,
      imgs: [],
    }
  }
  importAll = (r) => {
    return r.keys().map(r)
  }

  componentDidMount() {
    let { user_name } = JSON.parse(localStorage.getItem('user'))
    let request = JSON.parse(localStorage.getItem('request'))
    let Obj = {
      user: user_name,
    }

    axios
      .post('https://provider.beelicensed.com/providers/GetAllBUImg', Obj)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            username: user_name,
            imgs: res.data.img,
            request: request,
          })
        } else {
          console.log('some error\n')
          this.setState({
            username: user_name,
            request: request,
          })
        }
      })
      .catch((err) => {
        console.log('some error\n' + err)
        this.setState({
          username: user_name,
          request: request,
        })
      })
  }

  SubmitForm = (event) => {
    event.preventDefault()
  }
  SelectImg = (e) => {
    var page = e.target.src.substring(e.target.src)
    console.log(page)
    localStorage.setItem('StockImg', page)
    this.setState({ Direct: true })
  }

  render() {
    const images = this.state.imgs //this.importAll(require.context('../../../../../../uploads', false, /\.(SVG|JPE?G|JPG|PNG|png|jpg|jpe?g|svg)$/));
    let i = -1
    console.log(images)

    return (
      <div>
        {this.state.Direct ? <Redirect to={this.state.request ? '/Home/Update' : '/Home/NewProduct'} /> : null}
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <Form className="Form">
                <div className="card-header ">
                  <h4 className="card-title">Gebruik een stockfoto</h4>
                  <br />
                  Klik op de afbeelding om deze te selecteren.
                </div>

                <div className="card-body">
                  <Form.Row>
                    <Link to={this.state.request ? '/Home/Update' : '/Home/NewProduct'}>
                      <Button variant="danger">ANNULEER</Button>
                    </Link>
                  </Form.Row>

                  {images.map((el) => {
                    i++
                    if (i % 4 === 0)
                      return (
                        <Form.Row>
                          {images[i] ? (
                            <Form.Group as={Col}>
                              <img
                                src={images[i]}
                                value={i}
                                onClick={this.SelectImg}
                                className="composition__photo composition__photo--p1"
                              />
                            </Form.Group>
                          ) : (
                            ''
                          )}

                          {images[i + 1] ? (
                            <Form.Group as={Col}>
                              <img
                                src={images[i + 1]}
                                value={i + 1}
                                onClick={this.SelectImg}
                                className="composition__photo composition__photo--p1"
                              />
                            </Form.Group>
                          ) : (
                            ''
                          )}
                          {images[i + 2] ? (
                            <Form.Group as={Col}>
                              <img
                                src={images[i + 2]}
                                value={i + 2}
                                onClick={this.SelectImg}
                                className="composition__photo composition__photo--p1"
                              />
                            </Form.Group>
                          ) : (
                            ''
                          )}
                          {images[i + 3] ? (
                            <Form.Group as={Col}>
                              <img
                                src={images[i + 3]}
                                value={i + 3}
                                onClick={this.SelectImg}
                                className="composition__photo composition__photo--p1"
                              />
                            </Form.Group>
                          ) : (
                            ''
                          )}
                        </Form.Row>
                      )
                  })}

                  <Link to={this.state.request ? '/Home/Update' : '/Home/NewProduct'}>
                    <Button variant="danger">ANNULEER</Button>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Edit

import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  NavLink,
  Redirect,
} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css'
import './edit.css'
import Success from '../../Alert/Success'
import auth from './../../Auth/auth'
import axios from 'axios'

class Cat extends React.Component {
  constructor(props) {
    super(props)
    let { categories, user_name } = JSON.parse(localStorage.getItem('user'))
    this.state = {
      username: user_name,
      Cat: categories,
      categories: [],group:'',
      status: false,
      Success: false,
      redirect: false,
      multiple:true,
      msg: '',
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'));
    let { title } =JSON.parse(localStorage.getItem('user-group'));
    let multiple=true;
    if(title===auth.UserGroupTitle.Loyalswan)
    multiple=false;
    console.log(user)
    axios
      .post('https://provider.beelicensed.com/providers/GetAllCategories&Selected', user)
      .then((res) => {
        if (res.data.status === 200) this.setState({ categories: res.data.cat, Cat: res.data.selected,group:title,multiple:multiple })
        else console.log('status 204')
      })
      .catch((err) => {
        console.log('error')
      })
  }

  SubmitForm = (event) => {
    event.preventDefault()
    console.log(this.state);
    if (this.state.Cat.length < 1)
      this.setState({ status: true, Success: false, msg: 'Atleast 1 category should be selected' })
    else {
      axios
        .post('https://provider.beelicensed.com/providers/SelectCategories', this.state)
        .then((result) => {
          if (result.data.status === 200) {
            
           // localStorage.setItem('user', JSON.stringify(result.data.user))
            // localStorage.setItem('cat', JSON.stringify(ca));
            this.setState({ status: true, Success: true, msg: '' })
          } else this.setState({ status: true, Success: false, msg: result.data.msg })
        })
        .catch((err) => {
          this.setState({ status: true, Success: false, msg: err.data.msg })
        })
    }
  }

  HideStatus = (e) => {
    if (this.state.Success) this.setState({ status: false, redirect: true })
    else this.setState({ status: false, redirect: false })
  }
  render() {
    const categories = this.state.categories
    let i = 0
    return (
      <div>
        {this.state.redirect ? (
          <Redirect
            to={{
              pathname: '/Home',
              state: { User: this.state },
            }}
          />
        ) : null}
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <Form className="Form" onSubmit={this.SubmitForm}>
                <div className="card-header ">
                  <h4 className="card-title">in welke categorieën specialiseert u zich?</h4>
                </div>

                <div className="card-body">
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>Categorieën </Form.Label>
                      <Form.Control
                        as="select"
                        multiple={this.state.multiple}
                        onChange={(e) => {
                          let Cat2 = this.state.Cat
                          if(!(this.state.group === auth.UserGroupTitle.Loyalswan))
                          if (Cat2.includes(e.target.value)) {
                            let i = Cat2.indexOf(e.target.value)
                            Cat2.splice(i, 1)
                          } else Cat2.push(e.target.value)
                          if(this.state.group === auth.UserGroupTitle.Loyalswan)
                          {
                            let arra=[];
                            arra.push(e.target.value)
                            Cat2 = arra;
                            console.log(Cat2)
                            
                          }
                          this.setState({ Cat: Cat2 })
                        }}
                      >
                        <option value="none" selected disabled hidden>
                          Kies een regio
                        </option>

                        {categories.map((el) => {
                          const { name, value } = el
                          if (this.state.Cat.includes(value)) {
                            console.log(value)
                            return (
                              <option key={name} className="Selected" value={el.value} selected>
                                {name}
                              </option>
                            )
                          } else {
                            return (
                              <option key={name} className="unSelected" value={value}>
                                {name}
                              </option>
                            )
                          }
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Button variant="primary" type="submit">
                    PROFIEL BJIWERKEN
                  </Button>
                  <Link to="/Home">
                    <Button variant="danger">ANNULEER</Button>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {this.state.status ? (
          <Success msg={this.state.msg} status={this.state.Success} OnClose={this.HideStatus} />
        ) : null}
      </div>
    )
  }
}
export default Cat

import React from 'react'

import { MDBDataTable } from 'mdbreact'
import { Link, NavLink } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../edit.css'
import axios from 'axios'
import './Success.css'
import '../edit.css'
import auth from '../../../Auth/auth'
const column = [
  {
    label: '#',
    field: 'no',
    sort: 'asc',
    width: 50,
  },
  {
    label: 'Artikel',
    field: 'Artikel',
    sort: 'asc',
    width: 200,
  },

  {
    label: 'Omschrijving',
    field: 'Desc',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'Datum',
    field: 'date',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Aankomsttijd',
    field: 'arrival',
    width: 100,
  },
  {
    label: 'Aantal acties',
    field: 'action',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Naam',
    field: 'name',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Telefoon',
    field: 'phone',
    sort: 'asc',
    width: 100,
  },
]
const column2 = [
  {
    label: '#',
    field: 'no',
    sort: 'asc',
    width: 50,
  },
  {
    label: 'Artikel',
    field: 'Artikel',
    sort: 'asc',
    width: 200,
  },

  {
    label: 'Omschrijving',
    field: 'Desc',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'Datum',
    field: 'date',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Aankomsttijd',
    field: 'arrival',
    width: 100,
  },
  {
    label: 'Aantal acties',
    field: 'action',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Naam',
    field: 'name',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Telefoon',
    field: 'phone',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Voucher/Settled',
    field: 'settle',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'total',
    field: 'total',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'discount',
    field: 'discount',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'paid',
    field: 'paid',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Commision',
    field: 'commision',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Commision State',
    field: 'commisionstate',
    sort: 'asc',
    width: 100,
  },

  {
    label: 'Settle Datum',
    field: 'Date',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'Settle time',
    field: 'Time',
    sort: 'asc',
    width: 100,
  },
]
class Res extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAlert: false,
      Print: false,
      totalAmountToPaid:0,
      columns: column,
      clicked: -1,
      rows: [
        {
          id: 1,
          no: '',
          Artikel: '',
          Desc: '',
          date: '',
          arrival: '',
          action: '',
          name: '',
          phone: '',
          settle: false,
          Date: '',
          Time: '',
        },
      ],
    }
  }
  milliSecondToMinute = (data) => {
    var minutes = Math.floor(data / 60000);
    var seconds = ((data % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  componentDidMount() {
    let { user_name } = JSON.parse(localStorage.getItem('user'))
    let UserGroup = JSON.parse(localStorage.getItem('user-group'));
    console.log(UserGroup)
    let obj = {
      username: user_name,
    }
    var totalAmountToPaidLocal = 0;
    var reservationsIdStr = "";
    axios
    // .post('http://localhost:53000/providers/GetReservations', obj)
    .post('https://provider.beelicensed.com/providers/GetReservations', obj)
      .then((result) => {
        if (result.data.status === 200) {
          let dataObj = [];
          if (!(UserGroup.title == auth.UserGroupTitle.Poppingtime)) {
         
            result.data.data.forEach((element, index) => {
              let n = element.createdAt
              let d = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()
              if (element.ProductId) {
                let obj2 = {
                  id: element._id,
                  no: index + 1,
                  Artikel: element.ProductId.Name,
                  Desc: element.ProductId.ProductDesc,
                  date: d,
                  arrival: element.ArrivalTime,
                  action: element.BookedSlots,
                  name: user_name,
                  phone: element.CU.profile.phoneNo,
                }
                dataObj.push(obj2);
              }
            }

            );
            this.setState({ rows: dataObj, columns: column })
          }
          else if (UserGroup.title == auth.UserGroupTitle.Poppingtime) {

            result.data.data.forEach((element, index) => {
              let n = new Date(element.createdAt)
              let d = n.getFullYear() + '-' + (n.getMonth() + 1) + '-' + n.getDate()
              if (element.ProductId) {
                if (element.status === "Settled" && !element.paymentStatus && element.ProductId.SubCat.isFixed) {
                  totalAmountToPaidLocal = totalAmountToPaidLocal + element.ProductId.SubCat.commission;
                  reservationsIdStr=element._id + "," + reservationsIdStr;
                }
                else if(element.status === "Settled" && !element.paymentStatus && element.ProductId.SubCat.isPercentage){
                  totalAmountToPaidLocal = totalAmountToPaidLocal + (element.Reservation.grand_total * (element.ProductId.SubCat.commission/100))
                  reservationsIdStr= element._id + "," + reservationsIdStr;
                }
                let obj2 = {
                  id: element._id,
                  no: index + 1,
                  Artikel: element.ProductId.Name,
                  Desc: element.ProductId.ProductDesc,
                  date: d,
                  arrival: element.ArrivalTime,
                  action: element.BookedSlots,
                  total: element.Reservation.total,
                  discount: element.Reservation.discount,
                  paid: element.Reservation.grand_total,
                  commision: element.ProductId.SubCat.commission,
                  commisionstate: element.ProductId.SubCat.isFixed ? "Fixed Price" : "Percentage Amount",
                  name: element.CU.username,
                  phone: element.CU.profile.phoneNo,
                  settle: element.status,
                  Date: element.status === "Settled" ? new Date(element.updatedAt).toDateString() : "",
                  Time: element.status === "Settled" ? new Date(element.updatedAt).toTimeString() : "",
                }
                dataObj.push(obj2);
              }
            }

            );
            this.setState({totalAmountToPaid:totalAmountToPaidLocal});
            this.setState({ rows: dataObj, columns: column2 })
            localStorage.setItem('reservationsIdStr', reservationsIdStr)
          }
        } else console.log('No data')
      })
      .catch((err) => {

        console.log('Error' + err)
      })
  }

  render() {
    const data = {
      columns: this.state.columns,
      rows: this.state.rows,
    }
    var totalAmountToPaid = this.state.totalAmountToPaid;
    return (
      <div>
        <div className="editContainer">
          <div className="content">
            <div className="row">
              <div className="Form">
                <div className="card-header  ">
                  <h4 className="card-title AddHead">
                    Reservations
                    <Link to={`/Home/PayComission/${totalAmountToPaid}`}>
                      <div className="AddLogo">{`Pay Invoice of ${totalAmountToPaid}`}</div>
                    </Link>
                  </h4>
                </div>
                <div className=" TableScroll">
                  <MDBDataTable hover striped responsiveMd data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Res

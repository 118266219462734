import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import auth from '../Auth/auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.getAuthentication()) return <Component {...props} />
        else
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          )
      }}
    />
  )
}
export default PrivateRoute

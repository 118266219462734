import { MDBCol, MDBContainer, MDBFooter, MDBRow } from 'mdbreact'
import React from 'react'
import './foot.css'
const Footer = () => {
  return (
    <MDBFooter color="blue" style={{ marginTop: '0px!important' }} className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="1"></MDBCol>
          <MDBCol md="2">
            <ul className="centered">
              <li className="list-unstyled">Bureau Integra BV</li>
              <li className="list-unstyled ">Wijngaardenlaan 17</li>
              <li className="list-unstyled">2252 XJ Voorschoten</li>
            </ul>
          </MDBCol>

          <MDBCol md="2">
            <ul className="centered">
              <li className="list-unstyled ">Skype: coffeecup17ms</li>
              <li className="list-unstyled ">Mail:info@beelicensed.com</li>
            </ul>
          </MDBCol>
          <MDBCol md="5"></MDBCol>
          <MDBCol md="1">
            <a href="/uploads/terms.pdf" target="_blank">
              Voorwaarden
            </a>
          </MDBCol>
          <MDBCol md="1">
            <a href="/uploads/privacy.pdf" target="_blank">
              Privacy
            </a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>&copy; {new Date().getFullYear()} auteursrechten zijn voorbehouden.</MDBContainer>
      </div>
    </MDBFooter>
  )
}

export default Footer
